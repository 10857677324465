import Cookies from 'js-cookie'
import { DEFAULT_LOCATION, LOCATION_COOKIE_NAME } from '../constants'
import { LocationConfig } from '../types/LocationConfig'

interface GetLocationValue {
  locationConfig?: LocationConfig
  domain?: string
}

interface SetLocationValue {
  location: string
  domain?: string
}

export const getLocationValue = ({
  locationConfig,
  domain,
}: GetLocationValue) => {
  let cookieLookupName = LOCATION_COOKIE_NAME
  let location = DEFAULT_LOCATION.value
  if (locationConfig && locationConfig.value) {
    location = locationConfig.value
  } else if (locationConfig && locationConfig.cookieName) {
    cookieLookupName = locationConfig.cookieName
    location = Cookies.get(cookieLookupName) || DEFAULT_LOCATION.value
  } else {
    location = Cookies.get(cookieLookupName) || DEFAULT_LOCATION.value
  }
  setLocationValue({ location, domain })

  return location
}

const setLocationValue = ({ location, domain }: SetLocationValue) => {
  const finalDomain = domain
    ? getRootDomain(domain)
    : typeof window !== 'undefined'
    ? getRootDomain(window.location.hostname)
    : null
  const cookieObject: Cookies.CookieAttributes = {
    secure: false,
    sameSite: 'Lax',
    expires: 7,
  }
  if (finalDomain) {
    cookieObject.domain = finalDomain
  }
  Cookies.set(LOCATION_COOKIE_NAME, location.toUpperCase(), cookieObject)
}

export const getRootDomain = (url: string) => {
  const splitReversedUrl = url.split('.').reverse()

  if (splitReversedUrl.length === 1 && splitReversedUrl[0]) {
    return `.${splitReversedUrl}`
  }

  return splitReversedUrl.length > 1
    ? `.${splitReversedUrl[1]}.${splitReversedUrl[0]}`
    : ''
}
