import React from 'react'

import { PromotionsView } from '../../components/views'
import { AppConfig } from '../../types/AppConfig'
import { SharedWrapper } from '../../utils/SharedWrapper'

interface PromotionsHubWebProps {
  config: AppConfig
}

export const PromotionsHubWeb: React.FC<PromotionsHubWebProps> = ({
  config,
}) => {
  return (
    <SharedWrapper config={config}>
      <PromotionsView />
    </SharedWrapper>
  )
}
