import { useState, useEffect, useContext } from 'react'
import {
  IPlacementQuery,
  IPlacementResponse,
  Placement,
} from '../types/PlacementQueryConfig'
import { Promotion } from '..'
import { AppStateContext } from '../contexts/AppContext'
import { PromoListContext } from '../contexts/PromotionListContext'
import { PROMO_LIST_ACTIONS } from '../reducers/promotionListReducer'
import { EventDescriptionType, EventType } from '../types'
import { DESKTOP } from '../constants'

interface State {
  loading: boolean
  placementId?: string
  totalPromotions: number
  promos: Promotion[]
}

export const allPromotionsDataStateInital: State = {
  loading: true,
  placementId: '',
  totalPromotions: 0,
  promos: [],
}

const defaultQuery: IPlacementQuery = {
  brand: 'FANDUEL',
  containers: [
    {
      id: '678',
    },
  ],
}

//TODO: modify response model when test server is ready
const parseApiData = (res: IPlacementResponse): Placement => {
  const placement: Placement =
    res.promoPlacements && res.promoPlacements.length
      ? res.promoPlacements.reduce((acc: any, curr: Placement) => {
          if (curr.promotions) {
            const actualPromos = acc.promos || []
            const currentPromos = curr.promotions || []
            acc.promos = actualPromos?.concat(currentPromos)
          }
          if (!acc.placementId) {
            acc.placementId = curr.placementId
          }
          if (!acc.totalPromotions) {
            acc.totalPromotions = 0
          }
          acc.totalPromotions = curr?.totalPromotions
            ? (acc.totalPromotions += curr?.totalPromotions)
            : acc.totalPromotions
          return acc
        }, {})
      : res.placement

  return {
    totalPromotions: placement?.totalPromotions,
    placementId: placement?.id || placement?.placementId,
    promos: placement?.promos,
  }
}

export const useAllPromotionsData = (
  page: number,
  placementQuery?: IPlacementQuery
) => {
  const [allPromotionsState, setAllPromotionsState] = useState<State>(
    allPromotionsDataStateInital
  )
  const query = placementQuery || defaultQuery
  const { axios, events } = useContext(AppStateContext)
  const { dispatch } = useContext(PromoListContext)

  const dispatchPostSuccessActions = (placement: Placement) => {
    dispatch({
      type: PROMO_LIST_ACTIONS.LOAD_PROMOS_SUCCESS,
      payload: {
        promos: placement.promotions || placement.promos,
        totalPromotions: placement.totalPromotions,
      },
    })
  }

  const dispatchPostErrorActions = () => {
    dispatch({
      type: PROMO_LIST_ACTIONS.LOAD_PROMOS_FAIL,
    })
  }

  const getData = (page: number) => {
    //TODO: if this is the api model we will use and we will need to accomodate more than one containers logic,
    //the pagination needs revisioning
    query.containers && query.containers[0] && (query.containers[0].page = page)
    const uri = '/api/customisedPromotions/retrieveStructured'
    try {
      events({
        type: EventType.ApiRequest,
        description: EventDescriptionType.RequestPromotions,
        uri,
      })
      axios
        .post(
          uri,
          { ...query, channel: DESKTOP },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          if (data.success !== undefined && data.success === false) {
            throw new Error('Success is false')
          }
          events({
            type: EventType.ApiSuccess,
            description: EventDescriptionType.RequestPromotions,
            uri,
          })
          const placement = parseApiData(data)
          setAllPromotionsState({
            placementId: placement.placementId,
            totalPromotions: placement.totalPromotions,
            promos: [
              ...(allPromotionsState.promos || []),
              ...(placement.promotions || placement.promos || []),
            ],
            loading: false,
          })

          dispatchPostSuccessActions(placement)
        })
        .catch((err) => {
          dispatchPostErrorActions()
          setAllPromotionsState({ ...allPromotionsState, loading: false })
          events({
            type: EventType.ApiError,
            description: EventDescriptionType.RequestPromotions,
            uri,
            error: err,
            message: err.message || 'API Error',
          })
          throw new Error(err)
        })
    } catch (e) {
      dispatchPostErrorActions()
      setAllPromotionsState({ ...allPromotionsState, loading: false })
    }
  }

  useEffect(() => {
    if (page) {
      getData(page)
    }
  }, [page])

  return allPromotionsState
}
