import { useState, useEffect, useContext } from 'react'
import { Promotion } from '../types/Promotion'
import { AppStateContext } from '../contexts/AppContext'
import { PromoListContext } from '../contexts/PromotionListContext'
import { PROMO_LIST_ACTIONS } from '../reducers/promotionListReducer'
import { EventDescriptionType, EventType } from '../types'
import { PromoDetailsContext } from '../contexts/PromotionDetailsContext'
import { PROMO_DETAILS_ACTIONS } from '../reducers/promotionDetailsReducer'
import { fetchSinglePromo } from '../utils/fetchSinglePromo'

interface State {
  loading: boolean
  promotion?: Promotion | null
}

export const singlePromotionStateInitial = {
  loading: false,
  promotion: null,
}

export const useSinglePromotionData = (promoCode?: string) => {
  const [singlePromotionState, setSinglePromotionState] = useState<State>(
    singlePromotionStateInitial
  )

  const { axios, events } = useContext(AppStateContext)
  const { dispatch: promoListDispatch } = useContext(PromoListContext)
  const { dispatch: promoDetailsDispatch } = useContext(PromoDetailsContext)

  const dispatchPostSuccessActions = (promo: Promotion) => {
    promoListDispatch({
      type: PROMO_LIST_ACTIONS.GET_PROMO_SUCCESS,
      payload: promo,
    })
    promoDetailsDispatch({
      type: PROMO_DETAILS_ACTIONS.LOAD_PROMO_SUCCESS,
      payload: promo,
    })
  }

  const disptachPostErrorActions = () => {
    promoListDispatch({
      type: PROMO_LIST_ACTIONS.GET_PROMO_FAIL,
      payload: promoCode,
    })
    promoDetailsDispatch({
      type: PROMO_DETAILS_ACTIONS.LOAD_PROMO_ERROR,
      payload: promoCode,
    })
  }

  const getPromotion = (promoCode: string) => {
    setSinglePromotionState({ ...singlePromotionState, loading: true })
    const uri = '/api/customisedPromotions/retrievePromotions'
    try {
      events({
        type: EventType.ApiRequest,
        description: EventDescriptionType.RequestSinglePromotion,
        uri,
        promoCode,
      })

      fetchSinglePromo(axios, promoCode)
        .then((data) => {
          if (data.success !== undefined && data.success === false) {
            throw new Error('Success is false')
          }
          events({
            type: EventType.ApiSuccess,
            description: EventDescriptionType.RequestSinglePromotion,
            uri,
            promoCode,
          })
          const promo = data.promotions
            ? data.promotions[0]
            : data.promotion
            ? data.promotion
            : null
          setSinglePromotionState({ promotion: promo, loading: false })
          dispatchPostSuccessActions(promo as Promotion)
        })
        .catch((err) => {
          events({
            type: EventType.ApiError,
            description: EventDescriptionType.RequestSinglePromotion,
            uri,
            error: err,
            message: err.message || 'API Error',
            promoCode,
          })
          disptachPostErrorActions()
          throw new Error(err)
        })
    } catch (e) {
      disptachPostErrorActions()
      console.error(JSON.stringify(e))
    }
  }

  useEffect(() => {
    if (promoCode) {
      getPromotion(promoCode)
    }
  }, [promoCode])

  return { singlePromotionState, getPromotion }
}
