import React from 'react'
import {
  OverlayItemDescriptionStyle,
  OverlayItemStyle,
  OverlayItemTitleStyle,
} from '../OverlayItemsStyles'

export interface NextStepProps {
  steps: string
}

export const NextStep: React.FC<NextStepProps> = ({ steps }) => {
  return (
    <OverlayItemStyle data-test-id="promotion-next-step" textAlign="left">
      <OverlayItemTitleStyle data-test-id="promotion-steps-title">
        Steps to qualify
      </OverlayItemTitleStyle>
      <OverlayItemDescriptionStyle data-test-id="promotion-steps-description">
        {steps}
      </OverlayItemDescriptionStyle>
    </OverlayItemStyle>
  )
}
