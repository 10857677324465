import React, { createContext } from 'react'
import { AxiosInstance } from 'axios'

import {
  DEFAULT_LOCATION,
  DEFAULT_API_ENDPOINT,
  DEFAULT_QUERY_ENDPOINT,
  DEFAULT_LANDING_PAGE_IMAGE,
  DEFAULT_PROMO_HUB_IMAGE,
  EMPTY_STATE,
} from '../constants'
import { LocationConfig } from '../types'
import { AppName } from '../types/AppName'
import { IPlacementQuery } from '../types/PlacementQueryConfig'
import { RouteConfig, RouteHandlerTypes } from '../types/RouteConfig'
import { Promotion } from '../types/Promotion'
import { getAxios } from '../utils/HttpService'
import { getLocationValue } from '../utils/getLocationValue'
import { Event } from '../types/AppEvent'
import { IEmptyState } from '../components/molecules/ErrorScreen/ErrorScreen'

export interface AppState {
  appName: AppName | null
  domain?: string
  location?: LocationConfig
  routeConfig: RouteConfig
  baseUrl?: string
  promoCode?: Promotion['promoCode']
  isLoggedIn?: boolean
  placementQueryConfig?: IPlacementQuery
  defaultPromoHubImageUrl: string
  defaultLandingPageImageUrl: string
  axios: AxiosInstance
  events?: (event: Event) => void
  emptyState?: IEmptyState
  anchorCallback?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  promotionDetailsPageTitle?: string
  hideBreadcrumbs?: boolean
}

interface AppCtxState {
  appName: AppName | null
  domain?: string
  location: string
  routeConfig: RouteConfig
  baseUrl?: string
  promoCode?: string
  isLoggedIn?: boolean
  placementQueryConfig: IPlacementQuery
  defaultPromoHubImageUrl: string
  defaultLandingPageImageUrl: string
  axios: AxiosInstance
  emptyState: IEmptyState
  events: (event: Event) => void
  anchorCallback?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  promotionDetailsPageTitle?: string
  hideBreadcrumbs?: boolean
}

const appCtxDefaultValue: AppCtxState = {
  appName: null,
  location: DEFAULT_LOCATION.value,
  domain: undefined,
  routeConfig: {
    routes: {
      [RouteHandlerTypes.LOGIN]: { label: '', href: '' },
      [RouteHandlerTypes.VIEW_PROMOTION]: { label: '', href: '' },
      [RouteHandlerTypes.JOIN]: { label: '', href: '' },
      [RouteHandlerTypes.PROMOTIONS]: { label: '', href: '' },
      [RouteHandlerTypes.ROOT]: { label: '', href: '' },
    },
  },
  baseUrl: DEFAULT_API_ENDPOINT,
  promoCode: undefined,
  defaultPromoHubImageUrl: DEFAULT_PROMO_HUB_IMAGE,
  defaultLandingPageImageUrl: DEFAULT_LANDING_PAGE_IMAGE,
  placementQueryConfig: DEFAULT_QUERY_ENDPOINT,
  axios: getAxios({ baseUrl: DEFAULT_API_ENDPOINT }),
  emptyState: EMPTY_STATE,
  events: () => null,
  anchorCallback: undefined,
  promotionDetailsPageTitle: undefined,
  hideBreadcrumbs: false,
}

export const AppStateContext = createContext(appCtxDefaultValue)

export const AppContextProvider: React.FC<AppState> = ({
  appName,
  domain,
  location,
  baseUrl,
  routeConfig,
  promoCode,
  placementQueryConfig = appCtxDefaultValue.placementQueryConfig,
  isLoggedIn,
  axios = getAxios({ baseUrl: baseUrl }),
  children,
  defaultLandingPageImageUrl,
  defaultPromoHubImageUrl,
  emptyState = appCtxDefaultValue.emptyState,
  events = () => null,
  anchorCallback,
  promotionDetailsPageTitle,
  hideBreadcrumbs,
}) => {
  return (
    <AppStateContext.Provider
      value={{
        appName,
        domain,
        location: getLocationValue({ domain, locationConfig: location }),
        baseUrl,
        promoCode,
        routeConfig,
        isLoggedIn,
        placementQueryConfig,
        defaultLandingPageImageUrl,
        defaultPromoHubImageUrl,
        axios,
        emptyState,
        events,
        anchorCallback,
        promotionDetailsPageTitle,
        hideBreadcrumbs,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}
