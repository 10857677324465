import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { PromotionCard } from './PromotionCard/PromotionCard'

import { Button } from '../../atoms/Button/Button'
import { Loader } from '../../atoms/Loader/Loader'
import { PromoListContext } from '../../../contexts/PromotionListContext'
import { PROMO_LIST_ACTIONS } from '../../../reducers/promotionListReducer'
import { Promotion } from '../../../types'
import { ErrorScreen } from '../../molecules/ErrorScreen/ErrorScreen'
import { ErrorScreenWrapper } from '../../molecules/ErrorScreen/ErrorScreenStyles'
import { AppStateContext } from '../../../contexts/AppContext'

export interface PromotionListProps {}

const PromotionListWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
`

const PromotionListFooter = styled.div`
  grid-column: 1/-1;
  justify-self: center;

  button {
    min-width: 120px;
    &:focus {
      outline: none;
    }
  }
`

const PromotionListStyle = styled.div`
  ${({
    theme: {
      promotionsHub: { itemsList },
      mediaQueries,
    },
  }) => `
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: ${itemsList?.columnGap || `1rem`};
  margin-bottom: 16px;
  &:focus {
    outline: none;
  }

  ${
    mediaQueries.extraLarge.minWidth &&
    `@media (min-width: ${mediaQueries.extraLarge.minWidth}) {
      grid-template-columns: repeat(4, minmax(320px, 1fr));
    }`
  }
`}
`

export const PromotionList: React.FC<PromotionListProps> = () => {
  const {
    promotionsHub: { buttonLoader },
  } = useTheme()

  const { emptyState } = useContext(AppStateContext)

  const {
    state: { promos, totalPromotions, loadingPromotions },
    dispatch,
  } = useContext(PromoListContext)

  const goToNextPage = () => {
    dispatch({ type: PROMO_LIST_ACTIONS.LOAD_PROMOS })
  }

  if (!loadingPromotions && !promos.length) {
    return (
      <ErrorScreenWrapper>
        <ErrorScreen title={emptyState.title} message={emptyState.message} />
      </ErrorScreenWrapper>
    )
  }
  return (
    <PromotionListWrapperStyle>
      <PromotionListStyle data-test-id="promotion-list">
        {promos?.map((promo: Promotion) => {
          return <PromotionCard promotion={promo} key={promo.promoCode} />
        })}
        {totalPromotions > promos.length && (
          <PromotionListFooter>
            <Button
              buttonType="secondary"
              dataTestId="load-more-promotions"
              onClick={() => goToNextPage()}
            >
              {loadingPromotions && promos.length ? (
                <Loader style={buttonLoader} />
              ) : (
                'Load More'
              )}
            </Button>
          </PromotionListFooter>
        )}
      </PromotionListStyle>
      {!promos.length && loadingPromotions && <Loader />}
    </PromotionListWrapperStyle>
  )
}
