import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  RadioButton,
  RadioButtonLabel,
} from '../../atoms/RadioButton/RadioButton'
import { PromoDetailsContext } from '../../../contexts/PromotionDetailsContext'
import { PROMO_DETAILS_ACTIONS } from '../../../reducers/promotionDetailsReducer'
import { IOptInOption } from '../../../types'
import { getIsOptedIn } from '../../../utils/geOptInStates'
import { SmallTick } from '../../../assets/icons/SmallTick'
import { Cross } from '../../../assets/icons/Cross'

export interface OptInOptionsProps {
  options: IOptInOption[]
}

const OptInOptionsStyle = styled.ul`
  list-style: none;
  padding-left: 30px;
`

const OptInOptionStyle = styled.li`
  ${({ theme }) => `
  font-size: ${theme.landing.optInOptions.text.size};
  font-weight: ${theme.landing.optInOptions.text.weight};
  color: ${theme.landing.optInOptions.text.color};
  display: flex;
  align-items: center;
  `}
`

const PostOptInOptionStyle = styled.div<{ selected?: boolean }>`
  ${({
    theme: {
      landing: {
        optInOptions: { postOptIn },
      },
    },
    selected,
  }) => `
    position: relative;
    margin-bottom: 16px;
    > ${RadioButtonLabel} {
      color: ${
        selected ? postOptIn.selected.text.color : postOptIn.text.color
      } !important;
      font-weight: ${
        selected ? postOptIn.selected.text.weight : postOptIn.text.weight
      };
    }
    > svg {
      margin-right: 12px;
    }
  `}
`

export const OptInOptions: React.FC<OptInOptionsProps> = ({ options }) => {
  const {
    dispatch,
    state: { promotion },
  } = useContext(PromoDetailsContext)

  const isOptedIn = promotion
    ? getIsOptedIn(promotion?.customerPromotionState.optInState)
    : false

  const {
    landing: { optInOptions },
  } = useTheme()
  const selectOptInOption = (id: string) => {
    if (id) {
      options = options.map((el) => {
        el.selected = el.id === id
        return el
      })
      dispatch({
        type: PROMO_DETAILS_ACTIONS.SELECT_OPT_IN_OPTION,
        payload: id,
      })
    }
  }
  return (
    <OptInOptionsStyle>
      {options.map((option) => {
        return (
          <OptInOptionStyle
            data-test-id={`opt-in-option-${option.id} ${
              option.selected ? 'selected' : ''
            }`}
            key={option.id}
          >
            {isOptedIn ? (
              <PostOptInOptionStyle data-test-id="post-opt-in-option">
                {option.selected ? (
                  <SmallTick fill={optInOptions.postOptIn.selected.icon.fill} />
                ) : (
                  <Cross fill={optInOptions.postOptIn.icon.fill} />
                )}
                <RadioButtonLabel>{option.value}</RadioButtonLabel>
              </PostOptInOptionStyle>
            ) : (
              <RadioButton
                id={option.id}
                checked={option.selected}
                label={option.value}
                onChange={selectOptInOption}
              />
            )}
          </OptInOptionStyle>
        )
      })}
    </OptInOptionsStyle>
  )
}
