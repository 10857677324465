import React from 'react'

import { AppConfig } from '../../types/AppConfig'
import { SharedWrapper } from '../../utils/SharedWrapper'
import { PromotionDetailsView } from '../../components/views'

interface PromotionsLandingWebProps {
  config: AppConfig
}

export const PromotionsLandingWeb: React.FC<PromotionsLandingWebProps> = ({
  config,
}) => {
  return (
    <SharedWrapper config={config}>
      <PromotionDetailsView />
    </SharedWrapper>
  )
}
