import { useContext, useState } from 'react'
import { AppStateContext } from '../contexts/AppContext'
import { PromoListContext } from '../contexts/PromotionListContext'
import { PROMO_LIST_ACTIONS } from '../reducers/promotionListReducer'
import { EventDescriptionType, EventType } from '../types/AppEvent'
import { PromoDetailsContext } from '../contexts/PromotionDetailsContext'
import { PROMO_DETAILS_ACTIONS } from '../reducers/promotionDetailsReducer'
import { getFormattedFreeSpinsLocation } from '../utils/getIsFreeSpinsReward'

export interface IOptInResponse {
  success: boolean
  errorCode?: string | null
  claimsLeft?: number
}

interface IOptInParams {
  optionParams?: {
    optionId: string
  }
  buyInParams?: {
    amount: number
  }
  freeSpinsParams?: {
    region: string
  }
}

interface IOptInRequest {
  attemptId: string
  promotionCode: string
  optInParams?: IOptInParams
}

interface State {
  loading: boolean
  response?: IOptInResponse
}

export const optInPromotionStateInitial: State = {
  loading: false,
}

export const useOptInPromotion = (callback?: () => void) => {
  const { axios, location, events } = useContext(AppStateContext)
  const { dispatch } = useContext(PromoListContext)
  const {
    dispatch: singlePromotionDispatch,
    state: { optInParam },
  } = useContext(PromoDetailsContext)
  const [optInState, setOptInState] = useState<State>(
    optInPromotionStateInitial
  )

  const optIn = (promoCode: string, shouldSendRegion?: boolean) => {
    setOptInState({ ...optInState, loading: true })

    const query: IOptInRequest = {
      promotionCode: promoCode,
      attemptId: `${new Date().getTime()}`,
    }

    if (optInParam) {
      query.optInParams = {
        optionParams: {
          optionId: optInParam,
        },
      }
    }
    if (shouldSendRegion) {
      query.optInParams = {
        freeSpinsParams: {
          region: getFormattedFreeSpinsLocation(location),
        },
      }
    }

    const dispatchPostOptInSuccessActions = () => {
      dispatch({
        type: PROMO_LIST_ACTIONS.OPT_IN_SUCCESS,
        payload: promoCode,
      })

      dispatch({
        type: PROMO_LIST_ACTIONS.GET_PROMO,
        payload: promoCode,
      })

      singlePromotionDispatch({
        type: PROMO_DETAILS_ACTIONS.OPT_IN_SUCCESS,
        payload: promoCode,
      })
    }

    const disptachPostOptInErrorActions = () => {
      dispatch({
        type: PROMO_LIST_ACTIONS.OPT_IN_FAIL,
        payload: promoCode,
      })

      singlePromotionDispatch({
        type: PROMO_DETAILS_ACTIONS.OPT_IN_ERROR,
        payload: promoCode,
      })
    }

    const uri = '/api/customisedPromotions/optIn'

    try {
      events({
        type: EventType.ApiRequest,
        description: EventDescriptionType.RequestOptIn,
        uri,
        promoCode: query.promotionCode,
      })
      axios
        .post(uri, query)
        .then((res) => res.data)
        .then((data) => {
          if (data.success !== undefined && data.success === false) {
            throw new Error('Success is false')
          }
          events({
            type: EventType.ApiSuccess,
            description: EventDescriptionType.RequestOptIn,
            uri,
            promoCode: query.promotionCode,
          })
          setOptInState({ loading: false, response: data })

          dispatchPostOptInSuccessActions()
          if (callback && data.success) {
            callback()
          }
        })
        .catch((err) => {
          events({
            type: EventType.ApiError,
            description: EventDescriptionType.RequestOptIn,
            uri,
            error: err,
            message: err.message || 'API Error',
            promoCode,
          })
          const response = {
            success: false,
            errorCode: err?.errorCode,
          }
          setOptInState({ loading: false, response: response })
          disptachPostOptInErrorActions()
        })
    } catch (e) {
      const response: IOptInResponse = {
        success: false,
      }
      setOptInState({
        loading: false,
        response: response,
      })

      disptachPostOptInErrorActions()
    }
  }

  return { optInState, optIn }
}
