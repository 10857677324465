import React from 'react'
import styled from 'styled-components'

import { Promotion } from '../../../../types/Promotion'
import { getHtmlWithPurgedStyles } from '../../../../utils/getHtmlWithPurgedStyles'

export interface CardDescriptionProps {
  description: Promotion['description']
}

const CardDescriptionStyle = styled.div`
  ${({ theme }) => `
    display: -webkit-box;
    margin-bottom: 16px;
    line-height: 21px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: ${theme.card.description.text.size};
    font-weight: ${theme.card.description.text.weight};
    font-family: ${theme.card.description.text.fontFamily || 'inherit'};
    color: ${theme.card.description.text.color};
    div,
    p,
    span {
      font-size: inherit;
    }
    -webkit-line-clamp: ${theme.card.description.lineClamp};
    -webkit-box-orient: vertical;
    ${
      theme.card.description.lineClamp
        ? `min-height: calc(21px * ${theme.card.description.lineClamp});`
        : `min-height: auto;`
    }
  `}
`

export const CardDescription: React.FC<CardDescriptionProps> = ({
  description,
}) => {
  return (
    <CardDescriptionStyle
      data-test-id="promotion-card-description"
      dangerouslySetInnerHTML={{
        __html: getHtmlWithPurgedStyles(description),
      }}
    />
  )
}
