import React from 'react'
import styled, { DefaultTheme } from 'styled-components'

import { Size } from '../../../types/ThemeType'

export interface BulletProps {
  size: Size
}

const BulletStyle = styled.span<{ theme: DefaultTheme; size: Size }>`
  ${({ theme, size }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: ${
      size === 'small' ? '1.25rem' : size === 'medium' ? '2rem' : '2.5rem'
    };
    height: ${
      size === 'small' ? '1.25rem' : size === 'medium' ? '2rem' : '2.5rem'
    };
    color: #ffffff;
    background-color: ${theme.landing.steps.bullet.background.color};
    font-size: ${
      size === 'small' ? '0.75rem' : size === 'medium' ? '1rem' : '1.2rem'
    };
    font-weight: ${theme.landing.steps.bullet.text.weight};
    border-radius: 50%
  `}
`
export const Bullet: React.FC<BulletProps> = ({ children, size }) => {
  return (
    <BulletStyle size={size} data-test-id="promotion-steps-bullet">
      {children}
    </BulletStyle>
  )
}
