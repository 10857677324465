export enum RouteHandlerTypes {
  LOGIN = 'login',
  VIEW_PROMOTION = 'view_promotion',
  JOIN = 'join',
  ROOT = 'root',
  PROMOTIONS = 'promotions',
}

export interface RouteHandler {
  label: string
  href: string
}

export type RouteConfig = {
  routes: Record<RouteHandlerTypes, RouteHandler>
}
