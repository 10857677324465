import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppStateContext } from '../../../contexts/AppContext'
import { EventType } from '../../../types/AppEvent'

export interface BreadcrumbsLinkProps {
  promoCode?: string
  dataTestId?: string
  isLast?: boolean
  href: string
}

const BreadcrumbsLinkStyle = styled.a`
  ${({ theme }) => `
    font-size: ${theme.breadcrumbs.text.size};
    color: ${theme.breadcrumbs.text.color};
    font-weight: ${theme.breadcrumbs.text.weight};
    font-family: ${theme.breadcrumbs.text.fontFamily || theme.fontFamily};
    text-decoration: none;
  `}
`
const BreadcrumbsLastLinkStyle = styled(BreadcrumbsLinkStyle)`
  ${({ theme }) => `
    font-family: ${
      theme.breadcrumbs.lastItemText?.fontFamily || theme.fontFamily
    }};
    font-weight: ${
      theme.breadcrumbs.lastItemText?.weight || theme.breadcrumbs.text.weight
    }
  `}
`

export const BreadcrumbsLink: React.FC<BreadcrumbsLinkProps> = ({
  children,
  promoCode,
  dataTestId,
  href,
  isLast,
}) => {
  const { events } = useContext(AppStateContext)

  const handleOnClick = () => {
    events({
      type: EventType.ButtonClick,
      label: children?.toString() || 'empty',
      href,
      promoCode,
    })
  }

  if (isLast) {
    return (
      <BreadcrumbsLastLinkStyle
        data-test-id={
          dataTestId || `breadcrumbs-link${promoCode ? `-${promoCode}` : ''}`
        }
        onClick={handleOnClick}
        href={href}
      >
        {children}
      </BreadcrumbsLastLinkStyle>
    )
  }

  return (
    <BreadcrumbsLinkStyle
      data-test-id={
        dataTestId || `breadcrumbs-link${promoCode ? `-${promoCode}` : ''}`
      }
      onClick={handleOnClick}
      href={href}
    >
      {children}
    </BreadcrumbsLinkStyle>
  )
}
