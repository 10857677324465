import styled from 'styled-components'

interface TermsStyleProps {
  hideContent: boolean
}

export const TermsStyle = styled.section<TermsStyleProps>`
  ${({ theme, hideContent }) => `
  margin-top: 2rem;
  max-height: ${hideContent ? '300px' : '100%'};
  overflow: hidden;
  position: relative;
  line-height: ${theme.landing.termsConditions.content.text.lineHeight}
`}
`

export const TermsTitleStyle = styled.h5`
  ${({ theme }) => `
  margin-bottom: 0.75rem;
  font-size: ${theme.landing.termsConditions.title.text.size};
  font-weight: ${theme.landing.termsConditions.title.text.weight};
  font-family: ${theme.landing.termsConditions.title.text.fontFamily};
  color: ${theme.landing.termsConditions.title.text.color};
  line-height: ${theme.landing.termsConditions.title.text.lineHeight};
  @media (max-width: ${theme.mediaQueries.mobile.maxWidth}) {
    font-size: ${theme.landing.termsConditions.title.text.mobileSize};
  }
`}
`

export const TermsContentStyle = styled.div`
  ${({ theme }) => `
  font-size: ${theme.landing.termsConditions.content.text.size};
  font-weight: ${theme.landing.termsConditions.content.text.weight};
  color: ${theme.landing.termsConditions.content.text.color};
  line-height: ${theme.landing.termsConditions.content.text.lineHeight};
  word-wrap: break-word;
   > * {
    font-size: ${theme.landing.termsConditions.content.text.size};
    font-weight: ${theme.landing.termsConditions.content.text.weight};
    color: ${theme.landing.termsConditions.content.text.color};
    @media (max-width: ${theme.mediaQueries.mobile.maxWidth}) {
        font-size: ${theme.landing.termsConditions.content.text.mobileSize};
    }
  }
  h1, h1, h3 {
    font-weight: bold;
  }
  b, b > span {
    color: ${theme.landing.termsConditions.title.text.color};
  }
  b {
    font-family: ${
      theme.landing.termsConditions.content?.bold?.fontFamily || 'inherit'
    };
  }
  ul {
   list-style: ${
     theme.landing.termsConditions.content.list?.unordered?.listItem?.bullet
       ?.listStyle || 'disc'
   };

   li {
     margin-left: ${
       theme.landing.termsConditions.content.list?.unordered?.marginLeft ||
       '0.8em'
     };
     padding-left: ${
       theme.landing.termsConditions.content.list?.unordered?.paddingLeft ||
       '7px'
     };
   }
  }
  ol {
    li {
      margin-left: ${
        theme.landing.termsConditions.content.list?.ordered?.marginLeft ||
        '1.4em'
      };
    }
  }
  i {
    font-style: italic;
  }
  table {
    border-collapse: ${
      theme.landing.termsConditions.content.table.borderCollapse
    };
    width: 100%;
    border: ${theme.landing.termsConditions.content.table.border.thickness} ${
    theme.landing.termsConditions.content.table.border.style
  } ${theme.landing.termsConditions.content.table.border.color};
    table-layout: ${theme.landing.termsConditions.content.table.layout};
  }
  th {
    font-weight: bold;
  }
  td, th {
    border-right: ${
      theme.landing.termsConditions.content.table.border.thickness
    } ${theme.landing.termsConditions.content.table.border.style} ${
    theme.landing.termsConditions.content.table.border.color
  };
    text-align: center;
    padding: ${theme.landing.termsConditions.content.table.cellPadding};
  }
  tr:nth-child(odd) {
    background-color: ${theme.landing.termsConditions.content.table.rowColor};
  }
  @media (max-width: ${theme.mediaQueries.mobile.maxWidth}) {
    font-size: ${theme.landing.termsConditions.content.text.mobileSize};
    line-height: ${theme.landing.termsConditions.content.text.mobileLineHeight};
  }
`}
`

interface TermsGradient {
  showAllTerms: boolean
}

export const TermsGradient = styled.div<TermsGradient>`
  ${({
    showAllTerms,
    theme: {
      landing: { termsConditions },
    },
  }) => `
    display: flex;
    align-items: flex-end;
    justify-content: center;
    ${
      showAllTerms
        ? ''
        : `
      position: absolute;
      z-index: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      background: linear-gradient(
        ${termsConditions.gradient.direction},
        ${termsConditions.gradient.start},
        ${termsConditions.gradient.end}
      );
      `
    }

  `}
`
