import React from 'react'
import styled from 'styled-components'

import { useCountDownTimer } from '../../../hooks/useCountDownTimer'

export interface StartingDateProps {
  promoOptInStartDate: string
  onTimeExpire?: () => void
}

const StartingDateStyle = styled.p`
  ${({ theme }) => `
    margin-top: 0;
    font-family: ${theme.timeLeft.text.fontFamily};
    font-size:  ${theme.timeLeft.text.size};
    color:  ${theme.timeLeft.text.color};
    weight:  ${theme.timeLeft.text.weight};
    text-transform: uppercase;
    letter-spacing: 1px;
  `}
`

export const StartingDate: React.FC<StartingDateProps> = ({
  promoOptInStartDate,
  onTimeExpire,
}) => {
  const startingDate = useCountDownTimer(promoOptInStartDate, onTimeExpire)

  if (!startingDate) return null

  return (
    <StartingDateStyle data-test-id="promotion-time-left">
      Time left to start {startingDate?.expirationDate}{' '}
    </StartingDateStyle>
  )
}
