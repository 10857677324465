import { BADGE } from './BadgeTypes'

export type Size = 'small' | 'medium' | 'large'

type WeightStyleType =
  | 'normal'
  | 'bold'
  | 'bolder'
  | 'lighter'
  | 'number'
  | 'initial'
  | 'inherit'

type BorderStyle =
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'

interface BorderStyleType {
  radius: string
  thickness: string
  color: string
  style: BorderStyle
}

type BackgroundRepeatType =
  | 'repeat'
  | 'repeat-x'
  | 'repeat-y'
  | 'no-repeat'
  | 'inherit'
  | 'space'
  | 'round'

interface BackgroundImageStyleType {
  url: string
  size?: string
  repeat?: BackgroundRepeatType
  position?: string
}

interface BackgroundStyleType {
  color: string
  image?: BackgroundImageStyleType
  opacity?: string
}

interface HoverStyleType {
  opacity: string
}
interface ButtonHoverStyleType {
  background: BackgroundStyleType
  text: TextStyleType
}
interface ButtonActiveStyleType {
  background: BackgroundStyleType
  text: TextStyleType
}

interface ButtonDisabledStyleType {
  background: BackgroundStyleType
  text: TextStyleType
}

interface TextStyleType {
  color: string
  size: string
  mobileSize?: string
  mobileLineHeight?: string
  weight: WeightStyleType
  align?: string
  fontFamily?: string
  lineHeight?: string
}

type TableLayoutType = 'auto' | 'fixed' | 'initial' | 'inherit'

type BorderCollapseType = 'separate' | 'collapse' | 'initial' | 'inherit'

interface TableStyleType {
  layout: TableLayoutType
  borderCollapse: BorderCollapseType
  border: BorderStyleType
  rowColor: string
  cellPadding: string
}

interface SVGType {
  fill: string
}

interface ListItemType {
  paddingLeft?: string
  marginLeft?: string
  listItem?: {
    paddingLeft?: string
    marginLeft?: string
    bullet?: {
      listStyle?: string
    }
  }
}

interface ListType {
  ordered?: ListItemType
  unordered?: ListItemType
}

interface CardStyleType {
  container: {
    background: BackgroundStyleType
    hover: HoverStyleType
    boxShadow?: string
    border?: BorderStyleType
  }
  title: {
    text: TextStyleType
  }
  description: {
    text: TextStyleType
    lineClamp?: number
  }
  footer?: {
    weight?: string
    fontFamily?: string
  }
  overlay: {
    background: BackgroundStyleType
    text: TextStyleType
  }
}

interface LandingStyleType {
  fontFamily?: string
  background: BackgroundStyleType
  title: {
    text: TextStyleType
  }
  description: {
    bold?: TextStyleType
    text: TextStyleType
    table: TableStyleType
    list?: ListType
  }
  steps: {
    title: {
      text: TextStyleType
    }
    step: {
      text: TextStyleType
    }
    bullet: {
      text: TextStyleType
      background: BackgroundStyleType
    }
    tick: SVGType
  }
  optInOptions: {
    text: TextStyleType
    postOptIn: {
      selected: {
        text: TextStyleType
        icon: SVGType
      }
      text: TextStyleType
      icon: SVGType
    }
  }
  cta: {
    container: BackgroundStyleType
  }
  termsConditions: {
    title: {
      text: TextStyleType
    }
    content: {
      text: TextStyleType
      table: TableStyleType
      bold?: TextStyleType
      list?: ListType
    }
    gradient: GradientStyleType
  }
  border?: BorderStyleType
  buttonLoader?: LoaderType
}

interface GradientStyleType {
  direction:
    | 'to bottom'
    | 'to bottom right'
    | 'to bottom left'
    | 'to right'
    | 'to left'
    | 'to top'
    | 'to top right'
    | 'to top left'
  start: string
  end: string
}

export interface BoxStyleType {
  all?: string
  top?: string
  bottom?: string
  right?: string
  left?: string
}

export interface ButtonStyleType {
  primary: {
    text: TextStyleType
    gradient: GradientStyleType
    border: BorderStyleType
    hover: ButtonHoverStyleType
    active: ButtonActiveStyleType
    disabled: ButtonDisabledStyleType
    height?: string
  }
  secondary: {
    text: TextStyleType
    gradient: GradientStyleType
    border: BorderStyleType
    hover: ButtonHoverStyleType
    active: ButtonActiveStyleType
    disabled: ButtonDisabledStyleType
    height?: string
  }
  outline: {
    text: TextStyleType
    background: BackgroundStyleType
    border: BorderStyleType
    hover: ButtonHoverStyleType
    active: ButtonActiveStyleType
    disabled: ButtonDisabledStyleType
    height?: string
  }
  text: {
    text: TextStyleType
    background: BackgroundStyleType
    border: BorderStyleType
    hover: ButtonHoverStyleType
    active: ButtonActiveStyleType
    disabled: ButtonDisabledStyleType
    height?: string
  }
}

interface SingleBadgeStyleType {
  background: BackgroundStyleType
  text: TextStyleType
}

interface PageTitleStyle {
  text: TextStyleType
}

interface BreadcrumbsStyle {
  text: TextStyleType
  lastItemText?: TextStyleType
}

interface TimeLeftType {
  text: TextStyleType & { fontFamily: string }
}

type BadgeStyleType = Record<BADGE, SingleBadgeStyleType>

interface ProgressBarStyleType {
  inProgress: {
    text: TextStyleType
    background: BackgroundStyleType
    progressBackground: BackgroundStyleType
  }
  completed: {
    text: TextStyleType
    background: BackgroundStyleType
    progressBackground: BackgroundStyleType
  }
}

export interface LoaderType {
  color: string
  size?: string
  gap?: string
  boxPadding?: BoxStyleType
}

interface ContainerStyleType {
  background?: BackgroundStyleType
  padding?: BoxStyleType
  margin?: BoxStyleType
}

interface ErrorScreenType {
  background: BackgroundStyleType
  title: TextStyleType
  message: TextStyleType
  padding: BoxStyleType
  container?: ContainerStyleType
}

enum MEDIA_QUERY {
  EXTRA_LARGE = 'extraLarge',
  LARGE = 'large',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

interface GridType {
  columnNumber: number | string
  columnGap?: string
}

interface BreakpointType {
  minWidth?: string
  maxWidth?: string
}

interface MediaQueries {
  [MEDIA_QUERY.EXTRA_LARGE]: BreakpointType
  [MEDIA_QUERY.LARGE]: BreakpointType
  [MEDIA_QUERY.TABLET]: BreakpointType
  [MEDIA_QUERY.MOBILE]: BreakpointType
}

interface PromotionsHub {
  itemsList: {
    columnGap: string
  }
  buttonLoader?: LoaderType
}

interface RadioButton {
  button: {
    unchecked: {
      background: BackgroundStyleType
      border: BorderStyleType
    }
    checked: {
      background: BackgroundStyleType
    }
  }
  text: TextStyleType
}

type ProductThemeType = 'default' | 'christmas'

export interface ThemeType {
  name: string
  fontFamily: string
  productTheme: ProductThemeType
  pageTitle: PageTitleStyle
  breadcrumbs: BreadcrumbsStyle
  card: CardStyleType
  button: ButtonStyleType
  badge: BadgeStyleType
  timeLeft: TimeLeftType
  landing: LandingStyleType
  progressBar: ProgressBarStyleType
  loader: LoaderType
  errorScreen: ErrorScreenType
  promotionsHub: PromotionsHub
  mediaQueries: MediaQueries
  radioButton: RadioButton
}
