import React from 'react'
import styled from 'styled-components'

export interface IBaseImage {
  url: string
  set?: string
  dataTestId?: string
}

export interface ImageProps extends IBaseImage {
  errorImageUrl: string
}

const ImageStyles = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`
export const Image: React.FC<ImageProps> = ({
  set,
  url,
  errorImageUrl,
  dataTestId,
}) => {
  const onErrorHandler = (event: any) => {
    event.target.src = errorImageUrl
    event.target.srcset = errorImageUrl
  }
  return (
    <ImageStyles
      src={url}
      srcSet={set}
      onError={onErrorHandler}
      data-test-id={dataTestId || `promotion-image-banner`}
    />
  )
}
