import {
  ICustomerPromotionState,
  IRewardDefinition,
  IStep,
  Promotion,
  RewardType,
} from '../types'

const getStepFromReward = (currency: string, reward?: IRewardDefinition) => {
  if (!reward) {
    return undefined
  }
  if (reward.type === RewardType.CashToMainWallet) {
    return {
      action: `Get your ${currency}${reward.value} bonus`,
      completed: reward.awarded || false,
      status: 'WAITING',
    }
  }
  if (reward.type === RewardType.FreeBetBonus) {
    if (reward.variableAmount) {
      const percentage = Math.round(100 * reward.variableAmount.ratio || 0)
      return {
        action: `Get ${percentage}% bonus up to ${currency}${reward.variableAmount.maxAmount}`,
        completed: reward.awarded || false,
        status: 'WAITING',
      }
    }
    return {
      action: `Get your ${currency}${reward.value} bonus`,
      completed: reward.awarded || false,
      status: 'WAITING',
    }
  }
  if (reward.type === RewardType.TransferToMain) {
    return {
      action: `Transfer to main`,
      completed: reward.awarded || false,
      status: 'WAITING',
    }
  }
  if (reward.type === RewardType.FreeSpins) {
    return {
      action: `${reward.awarded ? 'Got' : 'Get'} your free spins`,
      completed: reward.awarded || false,
      status: 'WAITING',
    }
  }
}

const noReward = (customerPromotionState: ICustomerPromotionState) =>
  !customerPromotionState.reward ||
  customerPromotionState.reward.type === RewardType.Void

export const getSteps = (promotion: Promotion): IStep[] => {
  const { customerPromotionState, currency } = promotion
  if (!customerPromotionState.reward) {
    return customerPromotionState.steps || []
  }
  const steps = [...(customerPromotionState.steps || [])]

  if (!noReward(customerPromotionState)) {
    const rewardStep = getStepFromReward(
      currency,
      customerPromotionState.reward
    )
    if (rewardStep) {
      steps.push(rewardStep)
    }
  }

  return steps
}
