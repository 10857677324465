import { getHtmlWithPurgedStyles } from './getHtmlWithPurgedStyles'

const predefinedSubHeading1 = 'How it works:'
const predefinedSubHeading2 = 'More details:'
const heading1List = new RegExp(
  '<[^>]*>' + predefinedSubHeading1 + '<[^>]*>.*?<(\\/ol|\\/ul)>',
  'g'
)
const heading2List = new RegExp(
  '<[^>]*>' + predefinedSubHeading2 + '<[^>]*>.*?<(\\/ol|\\/ul)>',
  'g'
)

export const getPromoDescriptionHtml = (body: string) => {
  // if true, add styling to put lists side by side
  const firstList = body.match(heading1List)?.[0]
  const secondList = body.match(heading2List)?.[0]
  if (firstList && secondList) {
    body = body.replace(
      firstList,
      `<div class="flexContainer"><div class="flexBlock">${firstList}</div>`
    )
    body = body.replace(
      secondList,
      `<div class="flexBlock">${secondList}</div></div>`
    )
  }

  const makeSubheadingsBold = (subheadingElement: Element) => {
    const elem = subheadingElement as HTMLElement
    if (
      elem.textContent === predefinedSubHeading1 ||
      elem.textContent === predefinedSubHeading2
    ) {
      elem.style.fontWeight = 'bold'
      elem.style.fontSize = '17px'
      elem.style.fontFamily = "'ProximaNovaBold', Arial"
    }
  }

  return getHtmlWithPurgedStyles(body, makeSubheadingsBold)
}
