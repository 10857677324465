import React from 'react'

export const SmallTick: React.FC<{
  fill: string
}> = ({ fill }) => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.82001 0.977472C8.60701 0.799472 8.29201 0.830472 8.11501 1.04047L3.46601 6.62047L0.853006 4.00747C0.658006 3.81247 0.341006 3.81247 0.146006 4.00747C-0.0489941 4.20247 -0.0489941 4.51947 0.146006 4.71447L3.14601 7.71447C3.24001 7.80847 3.36701 7.86047 3.50001 7.86047C3.50701 7.86047 3.51501 7.86047 3.52201 7.85947C3.66301 7.85347 3.79301 7.78847 3.88401 7.68047L8.88401 1.68047C9.06101 1.46947 9.03201 1.15447 8.82001 0.977472Z"
        fill={fill}
      />
    </svg>
  )
}
