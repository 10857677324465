import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Bullet } from '../Bullet/Bullet'
import { Tick } from '../../../assets/icons/Tick'
import { IStep } from '../../../types/CustomerPromotionState'

export interface StepsProps {
  steps: IStep[]
}

interface StepsDisplayProps {
  steps: IStep[]
  title: string
  type: string
}

const StepStyle = styled.li`
  ${({ theme }) => `
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    line-height: ${theme.landing.steps.step.text.lineHeight};
  `}
`
const StepsStyle = styled.ol`
  padding-left: 0;
  list-style: none;
`
const StepContentStyle = styled.span`
  ${({ theme }) => `
    padding-left: 10px;
    font-size: ${theme.landing.steps.step.text.size};
    font-weight: ${theme.landing.steps.step.text.weight};
    color: ${theme.landing.steps.step.text.color};
  `}
`
const StepsWrapperStyle = styled.div`
  color: #1f375b;
`

const StepsTitleStyle = styled.h5`
  ${({ theme }) => `
    font-size: ${theme.landing.steps.title.text.size};
    font-weight: ${theme.landing.steps.title.text.weight};
    color: ${theme.landing.steps.title.text.color};
    margin-bottom: 16px;
    font-family: ${theme.landing.steps.title.text.fontFamily};
  `}
`

export const StepsDisplay: React.FC<StepsDisplayProps> = ({
  steps,
  title,
  type,
}) => {
  const theme = useTheme()
  return (
    <>
      <StepsTitleStyle data-test-id={`promotion-steps-title-${type}`}>
        {title}
      </StepsTitleStyle>
      <StepsStyle>
        {steps.map((step, index) => (
          <StepStyle
            key={index}
            data-test-id={`promotion-step-${
              step.completed ? 'completed' : 'to-do'
            }`}
          >
            {step.completed ? (
              <Tick fill={theme.landing.steps.tick.fill} />
            ) : (
              <Bullet size="small">{index + 1}</Bullet>
            )}
            <StepContentStyle data-test-id="promotion-steps-action">
              {step.action}
            </StepContentStyle>
          </StepStyle>
        ))}
      </StepsStyle>
    </>
  )
}

export const Steps: React.FC<StepsProps> = ({ steps }) => {
  const totalSteps = steps.length
  const stepsCompleted = steps.filter((step) => step.completed)
  const stepsNotCompleted = steps.filter((step) => !step.completed)

  return (
    <StepsWrapperStyle data-test-id="promotion-steps-wrapper">
      {stepsCompleted.length !== 0 && (
        <StepsDisplay
          steps={stepsCompleted}
          title="Completed steps"
          type="completed"
        />
      )}
      {stepsCompleted.length !== totalSteps && (
        <StepsDisplay
          steps={stepsNotCompleted}
          type="to-do"
          title={
            stepsCompleted.length !== 0 ? 'Remaining steps' : 'Steps to qualify'
          }
        />
      )}
    </StepsWrapperStyle>
  )
}
