import styled from 'styled-components'

export const OverlayItemStyle = styled.dl<{ textAlign: string }>`
  ${({ textAlign }) => `
  text-align: ${textAlign};
  padding: 5px 16px;
  font-size: 10px;
`}
`

export const OverlayItemDescriptionStyle = styled.dd`
  margin-left: 0;
`

export const OverlayItemTitleStyle = styled.dt`
  text-transform: uppercase;
`
