import { Promotion, RewardType } from '../types'

const DEFAULT_COUNTRY_CODE_PREFIX = 'US-'
const SINGLE_SUBDIVISION_PATTERN = /[A-Z]{1,3}/

export const getIsFreeSpinsReward = (promotion?: Promotion) =>
  promotion &&
  promotion.customerPromotionState.reward?.type === RewardType.FreeSpins

export const getFormattedFreeSpinsLocation = (location: string) =>
  addCountryPrefixIfRequired(location.trim().toUpperCase())

const addCountryPrefixIfRequired = (location: string) =>
  SINGLE_SUBDIVISION_PATTERN.test(location)
    ? DEFAULT_COUNTRY_CODE_PREFIX.concat(location)
    : location
