import { ICustomerPromotionState } from './CustomerPromotionState'

export enum PromotionProduct {
  SPORTSBOOK = 'SPORTSBOOK',
  FANTASY = 'FANTASY',
  CASINO = 'CASINO', // TODO: What are the types for casino? e.g FD Casino and SD Casino
}

export enum PromotionBrand {
  FANDUEL = 'FANDUEL',
}

export enum PromotionStatus {
  ACTIVE = 'ACTIVE', // TODO: What other status are there??
  ENDED = 'ENDED',
  SCHEDULED = 'SCHEDULED',
  NOTENDED = 'NOTENDED',
  WAITING = 'WAITING', // this is declared in swagger but not present in pph enum
}

export interface IPromotionImage {
  url: string
  width: number
  height: number
}

export enum CallToActionType {
  OPT_IN = 'OPT_IN',
  REDIRECT = 'REDIRECT',
  REFRESH = 'REFRESH',
  BUY_IN = 'BUY_IN',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
}

export interface Promotion {
  promoCode: string
  title: string // TODO: This was missing in API - being looked at
  name: string
  description: string
  moreInfo?: string // This is how to guide
  termsAndConditions: {
    full: string
    url: string
  }
  images: IPromotionImage[]
  callToAction: {
    url: string
    text: string
    action: CallToActionType
  }
  theme: PromotionProduct
  brand: PromotionBrand
  products: PromotionProduct[]
  currency: string
  status: PromotionStatus
  registrationCode?: string
  endDate: string
  fulfillmentEndDate: Date
  optInStartDate?: string
  showTimeLeft: boolean
  customerPromotionState: ICustomerPromotionState
  tags?: string[]
}
