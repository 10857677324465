import { DefaultTheme } from 'styled-components'

import { AppName } from '../types'
import { fanduelCasino } from '../themes/fanduel-casino'
import { mohegansunCasino } from '../themes/mohegansun-casino'
import { stardustCasino } from '../themes/stardust-casino'

export const getTheme = (
  appName: AppName = AppName.FANDUEL_CASINO
): DefaultTheme => {
  const themes = {
    [AppName.FANDUEL_CASINO]: fanduelCasino,
    [AppName.STARDUST_CASINO]: stardustCasino,
    [AppName.MOHEGANSUN_CASINO]: mohegansunCasino,
  }

  return themes[appName]
}
