import React, { useContext, MouseEvent } from 'react'
import styled from 'styled-components'

import { AppStateContext } from '../../../../contexts/AppContext'
import { EventType } from '../../../../types/AppEvent'

interface CardActionAreaProps {
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  promoCode?: string
  href: string
}

const CardActionAreaStyles = styled.a`
  position: relative;
  height: 100%;
  text-decoration: none;
`

export const CardActionArea: React.FC<CardActionAreaProps> = ({
  children,
  promoCode,
  href,
}) => {
  const { events } = useContext(AppStateContext)
  const handleCardLinkOnClick = () => {
    events({
      href,
      promoCode,
      type: EventType.CardClick,
      label: 'empty',
    })
  }
  return (
    <CardActionAreaStyles href={href} onClick={handleCardLinkOnClick}>
      {children}
    </CardActionAreaStyles>
  )
}
