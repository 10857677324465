import { AppConfig } from '../types'
import axios, { AxiosInstance } from 'axios'

type HttpConfigType = Pick<AppConfig, 'baseUrl'>

export const getAxios = (conf: HttpConfigType): AxiosInstance => {
  return axios.create({
    baseURL: conf.baseUrl,
    withCredentials: true,
  })
}
