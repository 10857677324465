import { IStep } from './types'
import {
  IPlacementQuery,
  IPlacementContainer,
} from './types/PlacementQueryConfig'
import { IEmptyState } from './components/molecules/ErrorScreen/ErrorScreen'

//TODO change this to X-AuthToken when pph supports it
export const AUTH_TOKEN_COOKIE_NAME = 'X-Auth-Token'
export const LOCATION_COOKIE_NAME = 'X-Cpp-Region'
export const DEFAULT_LOCATION = { value: 'MI' }
export const DEFAULT_API_ENDPOINT = 'https://promos-api.fanduel.nxt.fndl.dev'
export const DEFAULT_LANDING_PAGE_IMAGE =
  'https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/default/landing-page.jpg'

export const DEFAULT_PROMO_HUB_IMAGE =
  'https://d3etwbgrcenr8i.cloudfront.net/cpp/fd/default/promo-hub.jpg'

export const DEFAULT_CONTAINER: IPlacementContainer = {
  id: 'Default-Fanduel',
  page: 1,
  context: {
    products: ['FANTASY', 'SPORTSBOOK', 'CASINO'],
  },
}

export const DEFAULT_QUERY_ENDPOINT: IPlacementQuery = {
  brand: 'FANDUEL',
  containers: [DEFAULT_CONTAINER],
}

export const OPT_IN_WITH_OPTIONS_STEP: IStep = {
  action: 'Choose one of the following options to opt in to the promotion',
  completed: false,
  status: 'WAITING',
}

export const EMPTY_STATE: IEmptyState = {
  title: "We're all out.",
  message: 'We are always updating our promotions check back soon.',
}

export const DESKTOP = 'desktop'
export const RGI = 'rgi'
export const REGION_PARAM_KEY = 'region'
export const INCENTIVE_GAMES_RGI = 'incentive_games'
export const THEME_PARAM = 'theme'
