import { AxiosInstance } from 'axios'
import { DESKTOP } from '../constants'
import { Promotion } from '../types'

export const fetchSinglePromo = async (
  axios: AxiosInstance,
  promoCode: string
) =>
  axios
    .post<{
      promotions?: Promotion[]
      promotion?: Promotion
      success?: boolean
    }>(
      '/api/customisedPromotions/retrievePromotions',
      { promoCodes: [promoCode], channel: DESKTOP },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((res) => res.data)
