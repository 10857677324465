//TODO tracker badge

import { Promotion, PromotionStatus, OptInState } from '../types'
import {
  comingSoon,
  completed,
  expired,
  accepted,
  bonusAwarded,
  BadgeType,
  notAvailable,
} from '../types/BadgeTypes'

const shouldCheckEligibilityForOptInState = (
  promoState: Promotion['customerPromotionState']
) => {
  return (
    promoState.optInState === OptInState.NOT_OPTED_IN ||
    promoState.optInState === OptInState.REMOVED
  )
}
const shouldCheckEligibilityForPromoStatus = (status: Promotion['status']) => {
  return (
    status !== PromotionStatus.SCHEDULED && status !== PromotionStatus.ENDED
  )
}

const isNotAvailable = (promo: Promotion): boolean | BadgeType => {
  return (
    shouldCheckEligibilityForOptInState(promo.customerPromotionState) &&
    shouldCheckEligibilityForPromoStatus(promo.status) &&
    !promo.customerPromotionState.eligibility.eligible &&
    notAvailable
  )
}
const isExpired = (promo: Promotion): boolean | BadgeType => {
  return (
    (promo.customerPromotionState.optInState === OptInState.EXPIRED ||
      (promo.status === PromotionStatus.ENDED &&
        promo.customerPromotionState.optInState === OptInState.NOT_OPTED_IN)) &&
    expired
  )
}

const isBonusAwarded = (promo: Promotion): boolean | BadgeType => {
  return (
    (promo.customerPromotionState.optInState === OptInState.COMPLETED ||
      promo.customerPromotionState.optInState === OptInState.REWARD_AWARDED) &&
    bonusAwarded
  )
}

const isAccepted = (promo: Promotion): boolean | BadgeType => {
  return (
    promo.customerPromotionState.optInState === OptInState.ONGOING && accepted
  )
}

const isCompleted = (promo: Promotion): boolean | BadgeType => {
  return (
    promo.customerPromotionState.optInState === OptInState.LOST && completed
  )
}

const isScheduled = (promo: Promotion): BadgeType | boolean => {
  return (
    promo.status === PromotionStatus.ACTIVE &&
    !!promo.optInStartDate &&
    new Date(promo.optInStartDate).valueOf() > Date.now() &&
    comingSoon
  )
}

export const getBadgeType = (promo: Promotion): BadgeType | null => {
  let badge: any = null

  if (promo) {
    const mappers = [
      isNotAvailable,
      isExpired,
      isScheduled,
      isCompleted,
      isAccepted,
      isBonusAwarded,
    ]

    for (let i = 0; i < mappers.length; i++) {
      if (mappers[i](promo)) {
        badge = mappers[i](promo)
        break
      }
    }
  }

  return badge || null
}
