import {
  ICustomerPromotionState,
  IOptInOption,
} from '../types/CustomerPromotionState'

export const getOptInOptions = (state: ICustomerPromotionState) => {
  let params: IOptInOption[] = []
  const available = state.criteriaState?.params?.optInOptions?.available
  const selected = state.criteriaState?.params?.optInOptions?.selected

  if (!available && !selected) {
    return null
  }

  if (available) {
    params = [...available]
  }

  if (selected) {
    params.forEach((el) => {
      if (el.id === selected.id) {
        el.selected = true
      }
    })
  }

  return params
}

export const getIsOptInOptions = (state: ICustomerPromotionState) =>
  !!state?.criteriaState?.params?.optInOptions
