export enum EventType {
  ButtonClick = 'BUTTON_CLICK',
  CardClick = 'CARD_CLICK',
  ApiRequest = 'API_REQUEST',
  ApiError = 'API_ERROR',
  ApiSuccess = 'API_SUCCESS',
  GenericError = 'GENERIC_ERROR',
}

export enum EventDescriptionType {
  RequestPromotions = 'REQUEST_PROMOTIONS',
  RequestSinglePromotion = 'REQUEST_SINGLE_PROMOTION',
  RequestOptIn = 'REQUEST_OPT_IN',
}

interface ClickEvent {
  label: string
  href?: string
  promoCode?: string
}

export interface ButtonClickEvent extends ClickEvent {
  type: EventType.ButtonClick
}

export interface CardClickEvent extends ClickEvent {
  type: EventType.CardClick
}

export interface FetchEvent {
  uri: string
  description: EventDescriptionType
  promoCode?: string
}

export interface FetchRequestEvent extends FetchEvent {
  type: EventType.ApiRequest
}

export interface FetchSuccessEvent extends FetchEvent {
  type: EventType.ApiSuccess
}

export interface FetchErrorEvent extends FetchEvent {
  type: EventType.ApiError
  message: string
  error: any
}

export interface GenericError {
  type: EventType.GenericError
  error: any
  message: string
}

export type Event =
  | ButtonClickEvent
  | CardClickEvent
  | FetchRequestEvent
  | FetchSuccessEvent
  | FetchErrorEvent
  | GenericError
