import React, { useContext } from 'react'
import styled from 'styled-components'

import { AppStateContext } from '../../../contexts/AppContext'
import { PromoDetailsContext } from '../../../contexts/PromotionDetailsContext'
import { RouteHandlerTypes } from '../../../types/RouteConfig'
import { getPromotionUrl } from '../../../utils/getPromotionUrl'
import { BreadcrumbsLink } from '../BreadcrumbsLink/BreadcrumbsLink'

export interface BreadcrumbsProps {
  page?: RouteHandlerTypes.PROMOTIONS | RouteHandlerTypes.VIEW_PROMOTION
  displayAlways?: boolean
  hasWrapper?: boolean
}

const BreadcrumbsStyle = styled.div<BreadcrumbsProps>`
  ${({ theme, displayAlways, hasWrapper }) => `
    font-size: ${theme.breadcrumbs.text.size};
    color: ${theme.breadcrumbs.text.color};
    font-weight: ${theme.breadcrumbs.text.weight};
    margin-bottom: ${hasWrapper ? 0 : '15px'};
    ${
      theme.mediaQueries.tablet.maxWidth &&
      !displayAlways &&
      `@media (max-width: ${theme.mediaQueries.tablet.maxWidth}) {
          display: none;
      }`
    }
`}
`

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  page = RouteHandlerTypes.PROMOTIONS,
  displayAlways,
  hasWrapper,
}) => {
  const { routeConfig, promoCode } = useContext(AppStateContext)
  const {
    state: { promotion, isLoadingPromotion },
  } = useContext(PromoDetailsContext)
  const {
    routes: { promotions, root, view_promotion },
  } = routeConfig

  if (page === RouteHandlerTypes.VIEW_PROMOTION && isLoadingPromotion) {
    return null
  }

  return (
    <BreadcrumbsStyle
      data-test-id="breadcrumb-section"
      displayAlways={displayAlways}
      hasWrapper={hasWrapper}
    >
      <BreadcrumbsLink href={root.href}>{root.label}</BreadcrumbsLink> /{' '}
      <BreadcrumbsLink
        href={promotions.href}
        isLast={RouteHandlerTypes.PROMOTIONS === page}
      >
        {promotions.label}
      </BreadcrumbsLink>
      {page === RouteHandlerTypes.VIEW_PROMOTION && (
        <>
          {' / '}
          <BreadcrumbsLink
            isLast={true}
            href={getPromotionUrl(view_promotion.href, promoCode)}
          >
            {promotion
              ? promotion.title || promotion.promoCode
              : promoCode || 'Selected promotion'}
          </BreadcrumbsLink>
        </>
      )}
    </BreadcrumbsStyle>
  )
}
