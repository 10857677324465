import React from 'react'
import {
  ErrorScreenStyle,
  ErrorScreenTitleStyle,
  ErrorScreenContentStyle,
} from './ErrorScreenStyles'

export interface IEmptyState {
  title?: string
  message?: string
}

export interface ErrorScreenProps extends IEmptyState {}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({ title, message }) => {
  return (
    <ErrorScreenStyle data-test-id="promotion-error-screen">
      {title && (
        <ErrorScreenTitleStyle data-test-id="promotion-error-title">
          {title}
        </ErrorScreenTitleStyle>
      )}
      {message && (
        <ErrorScreenContentStyle data-test-id="promotion-error-message">
          {message}
        </ErrorScreenContentStyle>
      )}
    </ErrorScreenStyle>
  )
}
