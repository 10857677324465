import React, { FC, Fragment } from 'react'
import { StartingDate } from '../StartingDate/StartingDate'
import { TimeLeft } from '../TimeLeft/TimeLeft'

interface PromoTimerProps {
  optInStartDate?: string
  stateExpiryDate?: string
  onStateExpire?: () => void
}

export const PromoTimer: FC<PromoTimerProps> = ({
  optInStartDate = '1970-01-01',
  stateExpiryDate = '',
  onStateExpire,
}) => {
  const isStartDateOutdated = new Date(optInStartDate).getTime() < Date.now()
  const isExpiryDateOutdated = new Date(stateExpiryDate).getTime() < Date.now()

  if (!isStartDateOutdated) {
    return (
      <Fragment>
        {optInStartDate !== '' && (
          <StartingDate
            onTimeExpire={onStateExpire}
            promoOptInStartDate={optInStartDate}
          />
        )}
      </Fragment>
    )
  }

  if (isStartDateOutdated && !isExpiryDateOutdated) {
    return (
      <Fragment>
        {stateExpiryDate !== '' && (
          <TimeLeft
            onTimeExpire={onStateExpire}
            promoStateExpiryDate={stateExpiryDate}
          />
        )}
      </Fragment>
    )
  }

  return null
}
