import React from 'react'

export const Cross: React.FC<{
  fill: string
  width?: string
  height?: string
}> = ({ fill }) => {
  return (
    <svg
      width="7"
      height="8"
      viewBox="0 0 7 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.20625 4.35974L6.85225 1.71374C7.04725 1.51874 7.04725 1.20174 6.85225 1.00674C6.65725 0.811739 6.34025 0.811739 6.14525 1.00674L3.49925 3.65274L0.85325 1.00674C0.65825 0.811739 0.34125 0.811739 0.14625 1.00674C-0.04875 1.20174 -0.04875 1.51874 0.14625 1.71374L2.79225 4.35974L0.14625 7.00574C-0.04875 7.20074 -0.04875 7.51774 0.14625 7.71274C0.24425 7.81074 0.37225 7.85874 0.50025 7.85874C0.62825 7.85874 0.75625 7.80974 0.85425 7.71274L3.50025 5.06674L6.14625 7.71274C6.24425 7.81074 6.37225 7.85874 6.50025 7.85874C6.62825 7.85874 6.75625 7.80974 6.85425 7.71274C7.04925 7.51774 7.04925 7.20074 6.85425 7.00574L4.20625 4.35974Z"
        fill={fill}
      />
    </svg>
  )
}
