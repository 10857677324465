import React from 'react'
import { LoaderStyles, LoaderWrapper } from './LoaderStyles'
import { LoaderType } from '../../../types'

export interface LoaderProps {
  templateOverride?: boolean
  style?: LoaderType
}

export const Loader: React.FC<LoaderProps> = ({
  children,
  templateOverride,
  style,
}) => {
  return (
    <LoaderWrapper data-test-id="loader">
      {templateOverride ? (
        children
      ) : (
        <LoaderStyles style={style}>
          <span />
          <span />
          <span />
        </LoaderStyles>
      )}
    </LoaderWrapper>
  )
}
