import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { NextStep } from './NextStep/NextStep'
import { TimeLeft } from './TimeLeft/TimeLeft'

export interface OverlayProps {
  promoStateExpiryDate?: string
  steps: string
  showTimeLeft: boolean
}

export const OverlayStyle = styled.div<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    z-index: 3;
    position: absolute;
    left: 0;
    bottom: 0;
    background: ${theme.card.overlay.background.color};
    color: ${theme.card.overlay.text.color};
    font-size: ${theme.card.overlay.text.size};
    font-weight: ${theme.card.overlay.text.weight};
    transform-origin: center bottom;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  `}
`

export const Overlay: React.FC<OverlayProps> = ({
  showTimeLeft,
  promoStateExpiryDate,
  steps,
}) => {
  return (
    <OverlayStyle data-test-id="promotion-overlay">
      <NextStep steps={steps} />
      {showTimeLeft && promoStateExpiryDate && (
        <TimeLeft promoStateExpiryDate={promoStateExpiryDate} />
      )}
    </OverlayStyle>
  )
}
