import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { CardTitle } from '../../Card/CardTitle/CardTitle'
import { Card, CardContentStyles } from '../../Card/Card'
import { Image } from '../../../atoms/Image/Image'
import { CardDescription } from '../../Card/CardDescription/CardDescription'
import { OverlayStyle } from '../../Overlay/Overlay'
import { CardActionArea } from '../../Card/CardActionArea/CardActionArea'

import { AppStateContext } from '../../../../contexts/AppContext'
import { PromoListContext } from '../../../../contexts/PromotionListContext'
import { CallToAction } from '../../../atoms/CallToAction/CallToAction'
import { Button } from '../../../atoms/Button/Button'
import { Promotion } from '../../../../types/Promotion'
import { getImageDetails } from '../../../../utils/getImageDetails'
import { Badge, BadgeStyles } from '../../../atoms/Badge/Badge'
import { getBadgeType } from '../../../../utils/getBadgeDetails'
import { BADGE } from '../../../../types/BadgeTypes'
import { getIsOptInOptions } from '../../../../utils/getOptInOptions'
import { getPromotionUrl } from '../../../../utils/getPromotionUrl'
import { useOptInPromotion } from '../../../../hooks/useOptInPromotion'
import { getIsFreeSpinsReward } from '../../../../utils/getIsFreeSpinsReward'
import { PromoTimer } from '../../PromoTimer/PromoTimer'
import { fetchSinglePromo } from '../../../../utils/fetchSinglePromo'
import { useCountDownTimer } from '../../../../hooks/useCountDownTimer'

export interface Image {
  url: string
  width: number
  height: number
}

export interface PromotionCardProps {
  promotion: Promotion
}

export const PromoBannerStyles = styled.div`
  position: relative;
`

const PromotionCardStyle = styled.div<{ theme: DefaultTheme }>`
  display: block;
  cursor: pointer;
  ${OverlayStyle} {
    transform: scaleY(0);
  }
  &: hover ${OverlayStyle} {
    transform: scaleY(1);
  }
  &: hover ${PromoBannerStyles} {
    opacity: 0.8;
  }
`

const CardFooter = styled.div`
  ${({ theme }) => `
  position: relative;
  padding: 16px;
  padding-top: 0px;
  display: flex;
  > :nth-child(2) {
    margin-left: 8px;
  }
  ${
    theme.card.footer &&
    `font-weight: ${theme.card.footer?.weight};
    font-family: ${theme.card.footer?.fontFamily};`
  }
`}
`

const CardContentHeader = styled.div<{ theme: DefaultTheme; type: BADGE }>`
  ${({ type, theme }) => `
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  ${BadgeStyles} {
    font-size: ${theme.badge[type].text.size};
    padding: 2px 4px;
    max-height: 17px;
    margin-right: 8px;
  }
`}
`

export const PromotionCard: React.FC<PromotionCardProps> = ({ promotion }) => {
  const [promo, setPromo] = useState<Promotion | null>(promotion)
  const { optIn } = useOptInPromotion()
  const { routeConfig, defaultPromoHubImageUrl, axios } = useContext(
    AppStateContext
  )

  const {
    state: { queue, ongoingPromotionCalls },
  } = useContext(PromoListContext)

  const {
    promotionsHub: { buttonLoader },
  } = useTheme()

  useEffect(() => {
    setPromo(promotion)
  }, [promotion])

  const handleTimeExpire = useCallback(async () => {
    if (!promo?.promoCode) return
    const result = await fetchSinglePromo(axios, promo.promoCode)
    if (result.promotions) setPromo(result.promotions[0])
    else setPromo(null)
  }, [])

  useCountDownTimer(promo?.customerPromotionState?.fulfillmentEndDate, () => {
    setPromo(null)
  })

  if (!promo) return null

  const {
    name,
    title,
    images,
    promoCode,
    callToAction,
    customerPromotionState,
    registrationCode,
    showTimeLeft,
    optInStartDate,
  } = promo
  const loading = [...queue, ...ongoingPromotionCalls].find(
    (el) => el === promoCode
  )
  const { promoStateExpiryDate } = customerPromotionState
  const filteredImages = images.filter(({ width }) =>
    [600, 300, 150].includes(width)
  )
  const isOptInOptions = getIsOptInOptions(customerPromotionState)
  const { url, set } = getImageDetails(filteredImages, defaultPromoHubImageUrl)
  const badge = getBadgeType(promo)

  const onCtaButtonClicked = () => {
    optIn(promoCode, getIsFreeSpinsReward(promo))
  }

  return (
    <PromotionCardStyle data-test-id={`promotion-card-${promoCode}`}>
      <Card>
        <CardActionArea
          href={routeConfig.routes.view_promotion.href.replace(
            ':promoCode',
            promoCode
          )}
          promoCode={promoCode}
        >
          <PromoBannerStyles>
            <Image
              url={url}
              set={set}
              errorImageUrl={defaultPromoHubImageUrl}
              dataTestId="promotion-banner-image-detailed-view"
            />
          </PromoBannerStyles>
          <CardContentStyles>
            <CardContentHeader
              data-test-id="card-content-header"
              type={badge ? badge.type : BADGE.DEFAULT}
            >
              {!!badge && <Badge label={badge.label} type={badge.type} />}
              {showTimeLeft && (
                <PromoTimer
                  onStateExpire={handleTimeExpire}
                  optInStartDate={optInStartDate}
                  stateExpiryDate={promoStateExpiryDate}
                />
              )}
            </CardContentHeader>
            {!!title && <CardTitle>{title}</CardTitle>}
            <CardDescription description={name}></CardDescription>
          </CardContentStyles>
        </CardActionArea>
        {isOptInOptions ? (
          <CardFooter>
            <Button
              promoCode={promoCode}
              buttonType="secondary"
              dataTestId={`choose-opt-in-options-${promoCode}`}
              width="100%"
              href={routeConfig.routes.view_promotion.href.replace(
                ':promoCode',
                promoCode
              )}
            >
              Choose opt in options
            </Button>
          </CardFooter>
        ) : (
          <CardFooter>
            <Button
              promoCode={promoCode}
              buttonType="outline"
              dataTestId={`more-info-button-${promoCode}`}
              width="50%"
              href={getPromotionUrl(
                routeConfig.routes.view_promotion.href,
                promoCode
              )}
            >
              More info
            </Button>
            <CallToAction
              buttonType="secondary"
              callToAction={callToAction}
              onClick={onCtaButtonClicked}
              dataTestId={`promo-list-cta-${promoCode}`}
              disabled={!!loading}
              loading={!!loading}
              width="50%"
              loaderStyle={buttonLoader}
              customerPromotionState={customerPromotionState}
              promoCode={promoCode}
              registrationCode={registrationCode}
            />
          </CardFooter>
        )}
      </Card>
    </PromotionCardStyle>
  )
}
