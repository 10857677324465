import React from 'react'
import styled from 'styled-components'
import { getPercentValue } from '../../../utils/getPercentValue'

export interface ProgressBarProps {
  ratio: number
}

const ProgressBarContainerStyle = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
`

interface ProgressStyleStyleProps {
  percent: number
}

const ProgressBarStyle = styled.div<ProgressStyleStyleProps>`
  width: 100%;
  background-color: ${({ theme, percent }) =>
    theme.progressBar[percent === 100 ? 'completed' : 'inProgress'].background
      .color};
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
`

interface ProgressTextStyleProps {
  percent: number
}

const ProgressText = styled.p<ProgressTextStyleProps>`
  margin-bottom: 8px;
  ${({ theme, percent }) => {
    const progressBar =
      theme.progressBar[percent === 100 ? 'completed' : 'inProgress']
    return `
      text-align: ${progressBar.text.align};
      font-size: ${progressBar.text.size};
      font-weight: ${progressBar.text.weight};
      color: ${progressBar.text.color};
    `
  }}
`

interface ProgressValueStyleProps {
  percent: number
}

const ProgressValueStyle = styled.span<ProgressValueStyleProps>`
  ${({ theme, percent }) => `
    display: block;
    width: ${percent}%;
    background-color: ${
      theme.progressBar[percent === 100 ? 'completed' : 'inProgress']
        .progressBackground.color
    };
    height: 100%;
  `}
`

export const ProgressBar: React.FC<ProgressBarProps> = ({ ratio }) => {
  const percent = getPercentValue(ratio)
  return (
    <ProgressBarContainerStyle data-test-id="promotion-progress-bar">
      <ProgressText data-test-id="promotion-progress-text" percent={percent}>
        {percent}% Complete
      </ProgressText>
      <ProgressBarStyle percent={percent}>
        <ProgressValueStyle
          percent={percent}
          data-test-id={`promotion-progress-value-${percent}`}
        />
      </ProgressBarStyle>
    </ProgressBarContainerStyle>
  )
}
