import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { ChevronLeft } from '../../../assets/icons/ChevronLeft'
import { AppStateContext } from '../../../contexts/AppContext'
import { PromoDetailsContext } from '../../../contexts/PromotionDetailsContext'
import { Promotion } from '../../../types/Promotion'
import { RouteHandlerTypes } from '../../../types/RouteConfig'

export interface PageTitleProps {
  page?: RouteHandlerTypes.PROMOTIONS | RouteHandlerTypes.VIEW_PROMOTION
}

const PageTitleStyle = styled.h1`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    font-size: ${theme.pageTitle.text.size};
    color: ${theme.pageTitle.text.color};
    font-weight: ${theme.pageTitle.text.weight};
    margin-bottom: 30px;
    ${
      theme.mediaQueries.tablet.maxWidth &&
      `@media (max-width: ${theme.mediaQueries.tablet.maxWidth}) {
          display: grid;
          grid-template-columns: 1fr repeat(3, auto) 1fr;
      }`
    }
`}
`
const PageTitleBackStyle = styled.a`
  margin-top: 3px;
  padding-left: 5px;
  padding-right: 15px;
`

const getTitle = (isViewPromoPage: boolean, promotion?: Promotion) => {
  if (!isViewPromoPage) {
    return 'Promotions'
  }
  return promotion && promotion.title ? promotion.title : 'Promotion'
}

export const PageTitle: React.FC<PageTitleProps> = ({
  page = RouteHandlerTypes.PROMOTIONS,
}) => {
  const { pageTitle } = useTheme()
  const {
    routeConfig: { routes },
  } = useContext(AppStateContext)
  const {
    state: { promotion, isLoadingPromotion },
  } = useContext(PromoDetailsContext)

  const isViewPromoPage = page === RouteHandlerTypes.VIEW_PROMOTION
  if (isViewPromoPage && isLoadingPromotion) {
    return null
  }

  return (
    <PageTitleStyle data-test-id="promotion-title">
      {isViewPromoPage && (
        <PageTitleBackStyle
          href={routes.promotions.href}
          data-test-id="page-title-back-button"
        >
          <ChevronLeft fill={pageTitle.text.color} />
        </PageTitleBackStyle>
      )}
      <span>{getTitle(isViewPromoPage, promotion)}</span>
    </PageTitleStyle>
  )
}
