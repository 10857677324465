import React, { useContext } from 'react'
import { RouteHandlerTypes } from '../../../types/RouteConfig'

import { PromoDetailsContextProvider } from '../../../contexts/PromotionDetailsContext'
import { Breadcrumbs } from '../../atoms/Breadcrumbs/Breadcrumbs'
import { PromotionDetailsState } from '../../molecules/PromotionDetails/PromotionDetailsState'
import { AppStateContext } from '../../../contexts/AppContext'

export interface PromotionDetailsViewProps {}

export const PromotionDetailsView: React.FC<PromotionDetailsViewProps> = () => {
  const { hideBreadcrumbs } = useContext(AppStateContext)

  return (
    <div>
      <PromoDetailsContextProvider>
        {!hideBreadcrumbs && (
          <Breadcrumbs page={RouteHandlerTypes.VIEW_PROMOTION} />
        )}
        <PromotionDetailsState />
      </PromoDetailsContextProvider>
    </div>
  )
}
