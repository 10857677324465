import { CallToActionType } from '../types/Promotion'
import {
  ICustomerPromotionState,
  OptInState,
  CriteriaCategory,
} from '../types/CustomerPromotionState'
import { isNil } from './js-utils'

export const getIsOptedIn = (optInState: OptInState) =>
  optInState === OptInState.ONGOING ||
  optInState === OptInState.COMPLETED ||
  optInState === OptInState.REWARD_AWARDED ||
  optInState === OptInState.LOST

export const getShouldShowProgress = (
  customerPromotionState: ICustomerPromotionState
) => {
  const isOptedIn = getIsOptedIn(customerPromotionState.optInState)
  return (
    isOptedIn &&
    !isNil(customerPromotionState.criteriaState?.ratio) &&
    customerPromotionState.criteriaState?.category !==
      CriteriaCategory.OPT_IN_OPTIONS
  )
}

export const getIsOptInCta = (
  isLoggedIn: boolean,
  customerPromotionState: ICustomerPromotionState
) => {
  return (
    isLoggedIn && customerPromotionState.optInState === 'NOT_OPTED_IN'
    // && !isAutoOptIn this condition needs to be present for a real life scenario
    // TODO: See if you can extract info about the auto opt in flag, if not, request from pph
  )
}

//TODO : what details do we need to check if cta is a redirect to promo hub?

export const isAutoOptIn = (
  callToActionType: CallToActionType,
  optInState: OptInState
) => callToActionType === 'REDIRECT' && !getIsOptedIn(optInState)
