import styled from 'styled-components'

export const ContainerStyles = styled.div`
  padding: 16px;
`

export const PromotionStyle = styled.div`
  ${({ theme: { landing, mediaQueries } }) => `
  max-width: 800px;
  margin: 0 auto;
  background-color: ${landing.background.color};
  ${
    landing.border &&
    `border-style: ${landing.border.style};
    border-width: ${landing.border.thickness};
    border-color: ${landing.border.color};
    border-radius: ${landing.border.radius};`
  }
  ${
    landing.fontFamily
      ? `font-family: ${landing.fontFamily};`
      : `font-family: inherit`
  }
  ${
    mediaQueries.mobile.maxWidth &&
    `@media (max-width: ${mediaQueries.mobile.maxWidth}) {
      border: 0;
    }`
  }
`}
`

export const CtaWrapperStyle = styled.div`
  ${({
    theme: {
      landing: { cta },
      mediaQueries,
    },
  }) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    mediaQueries.mobile.maxWidth &&
    `@media (max-width: ${mediaQueries.mobile.maxWidth}) {
      padding: 16px;
      background-color: ${cta.container.color};
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
    }`
  }
`}
`

export const HeadingStyle = styled.h3`
  ${({ theme }) => `
margin-bottom: 8px;
font-size: ${theme.landing.title.text.size};
font-weight: ${theme.landing.title.text.weight};
color: ${theme.landing.title.text.color};
line-height: ${theme.landing.title.text.lineHeight};
font-family: ${theme.landing.title.text.fontFamily || 'inherit'};
`}
`

export const DetailsText = styled.div`
  ${({ theme }) => `
  font-size: ${theme.landing.description.text.size};
  font-weight: ${theme.landing.description.text.weight};
  color: ${theme.landing.description.text.color};
  margin: 16px 0;
  word-wrap: break-word;
  line-height: ${theme.landing.description.text.lineHeight};
  b {
    font-family: ${theme.landing.description?.bold?.fontFamily || 'inherit'};
  }
  i {
    font-style: italic;
  }
  ol {
    padding-left: ${
      theme.landing.description?.list?.ordered?.paddingLeft || '16px'
    };
  }
  ul {
    padding-left: ${
      theme.landing.description?.list?.unordered?.paddingLeft || '16px'
    };
    list-style: ${
      theme.landing.description?.list?.unordered?.listItem?.bullet?.listStyle ||
      'disc'
    };
  }

  ul li {
    padding-left: ${
      theme.landing.description?.list?.unordered?.listItem?.paddingLeft ||
      'initial'
    }
  }
  table {
    border-collapse: ${theme.landing.description.table.borderCollapse};
    width: 100%;
    border:
    ${theme.landing.description.table.border.thickness}
    ${theme.landing.description.table.border.style}
    ${theme.landing.description.table.border.color};
    table-layout: ${theme.landing.description.table.layout};
  }
  th {
    font-weight: bold;
    font-size: ${theme.landing.steps.title.text.size};
    font-family: ${theme.landing.steps.title.text.fontFamily};
  }
  td, th {
    border-right:
     ${theme.landing.description.table.border.thickness}
     ${theme.landing.description.table.border.style}
     ${theme.landing.description.table.border.color};
    text-align: center;
    padding: ${theme.landing.description.table.cellPadding};
  }
  tr:nth-child(odd) {
    background-color: ${theme.landing.description.table.rowColor};
  }
  .flexContainer {
    display: flex;
  }
  .flexBlock {
    flex: 1;
  }
  .flexBlock:first-child {
    padding-right: 8px;
  }
  .flexBlock:not(:first-child) {
    padding-left: 8px;
  }
  ${
    theme.mediaQueries.mobile.maxWidth &&
    `@media (max-width: ${theme.mediaQueries.mobile.maxWidth}) {
      .flexContainer {
        flex-direction: column;
      }
      .flexBlock:first-child {
        padding-right: 0;
      }
      .flexBlock:not(:first-child) {
        padding-left: 0;
      }
    }`
  }
`}
`

export const LandingBadgeStyles = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`
