import React from 'react'

export const Tick: React.FC<{
  fill: string
}> = ({ fill }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47765 0 0 4.47765 0 10C0 15.5224 4.47765 20 10 20C15.5224 20 20 15.5224 20 10C20 4.47765 15.5224 0 10 0ZM10 18.8235C5.13529 18.8235 1.17647 14.8647 1.17647 10C1.17647 5.13529 5.13529 1.17647 10 1.17647C14.8647 1.17647 18.8235 5.13529 18.8235 10C18.8235 14.8647 14.8647 18.8235 10 18.8235ZM8.78471 12.6588L14.2541 6.09412C14.4624 5.84471 14.8318 5.81059 15.0824 6.01882C15.3318 6.22706 15.3659 6.59882 15.1576 6.84824L9.27529 13.9071C9.16824 14.0341 9.0153 14.1106 8.84941 14.1176H8.82353C8.66824 14.1176 8.51765 14.0565 8.40824 13.9459L4.87882 10.4165C4.64941 10.1871 4.64941 9.81412 4.87882 9.58471C5.10824 9.35529 5.48118 9.35529 5.71059 9.58471L8.78471 12.6588Z"
        fill={fill}
      />
    </svg>
  )
}
