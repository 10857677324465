import React, { MouseEvent, useContext } from 'react'
import styled from 'styled-components'
import { AppStateContext } from '../../../contexts/AppContext'
import { EventType } from '../../../types/AppEvent'

import { ButtonStyleType } from '../../../types/ThemeType'

export interface ButtonProps {
  buttonType: keyof ButtonStyleType
  full?: boolean
  width?: string
  href?: string
  disabled?: boolean
  dataTestId?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  promoCode?: string
  anchorCallback?: (e: MouseEvent<HTMLAnchorElement>) => void
}

interface ButtonStyleProps {
  buttonType: keyof ButtonStyleType
  full: boolean
  width: string | undefined
  disabled?: boolean
}

const ButtonStyles = styled.button<ButtonStyleProps>`
  ${({ buttonType, theme, full, width }) => {
    const buttonStyle = theme.button[buttonType]
    return `
    font-family: ${buttonStyle.text.fontFamily || 'inherit'};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 14px 12px;
    cursor: pointer;
    font-size: ${buttonStyle.text.size};
    font-weight: ${buttonStyle.text.weight};
    text-decoration: none;
    ${buttonStyle.height ? `height: ${buttonStyle.height};` : ''}
    color: ${buttonStyle.text.color};
    ${
      buttonType === 'primary' || buttonType === 'secondary'
        ? `
        background-image: linear-gradient(${theme.button[buttonType].gradient.direction}, ${theme.button[buttonType].gradient.start} 0%, ${theme.button[buttonType].gradient.end} 100%);
        `
        : `
        background-color: ${theme.button[buttonType].background.color};
        `
    }
    border: ${buttonStyle.border.thickness} solid ${buttonStyle.border.color};
    border-radius: ${theme.button[buttonType].border.radius};
    ${full ? `width: 100%` : ''};
    ${width ? `width: ${width}` : ''};
    &:hover {
      background-image: none;
      background-color: ${buttonStyle.hover.background.color};
      color: ${buttonStyle.hover.text.color};
    }
    &:active {
      background-image: none;
      background-color: ${buttonStyle.active.background.color};
      color: ${buttonStyle.active.text.color};
      box-shadow: inset 0px 0px 4px rgba(68, 80, 88, 0.5);
    }
    &:disabled {
      background-image: none;
      background-color: ${buttonStyle.disabled.background.color};
      color: ${buttonStyle.disabled.text.color};
      cursor: not-allowed
    }
    .chevron {
      fill: ${buttonStyle.text.color}
      &:hover {
        fill: ${buttonStyle.hover.text.color}
      }
    }
  `
  }};
`

export const Button: React.FC<ButtonProps> = ({
  onClick,
  buttonType,
  width,
  href = '',
  disabled = false,
  full = false,
  promoCode,
  children,
  dataTestId = '',
  anchorCallback,
}) => {
  const { events } = useContext(AppStateContext)

  const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    events({
      type: EventType.ButtonClick,
      label: children?.toString() || 'empty',
      promoCode,
    })
    if (onClick) {
      onClick(e)
    }
  }

  const handleLinkOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const { href: anchorHref } = e.target as HTMLAnchorElement
    events({
      type: EventType.ButtonClick,
      label: children?.toString() || 'empty',
      href: anchorHref,
      promoCode,
    })
    if (anchorCallback) {
      anchorCallback(e)
    }
  }

  return href ? (
    <ButtonStyles
      as="a"
      buttonType={buttonType}
      full={full}
      width={width}
      href={href}
      disabled={disabled}
      data-test-id={
        dataTestId || `button-redirect${promoCode ? `-${promoCode}` : ''}`
      }
      onClick={handleLinkOnClick}
    >
      {children}
    </ButtonStyles>
  ) : (
    <ButtonStyles
      type="button"
      buttonType={buttonType}
      onClick={handleOnClick}
      data-test-id={
        dataTestId || `button-action${promoCode ? `-${promoCode}` : ''}`
      }
      full={full}
      width={width}
      disabled={disabled}
    >
      {children}
    </ButtonStyles>
  )
}
