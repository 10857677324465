type GamesIdentifier = { code: string }

type RestrictionType = { value: string }

export enum OptInState {
  NOT_OPTED_IN = 'NOT_OPTED_IN',
  ONGOING = 'ONGOING',
  REWARD_AWARDED = 'REWARD_AWARDED',
  LOST = 'LOST',
  REMOVED = 'REMOVED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED',
}

export enum CriteriaCategory {
  GAUGE = 'GAUGE',
  VALUE = 'VALUE',
  OPT_IN_OPTIONS = 'OPT_IN_OPTIONS',
}

//details regarding a step a user needs to take to progress in a promotion
export interface IStep {
  action: string
  completed: boolean
  status: string //TODO, replace this with enum values
}

//encapsulates details about a tier built promotion
export interface ITierDefinition {
  name: string
  level: number
}

//Encapsulates various eligibility details for a user regarding a promotion
export interface IEligibility {
  canOptIn: boolean
  canConsent: boolean // Flag indicating that the customer can give consent in regards to being opted in.
  eligible: boolean
}

// holds information about the opt in options a user can select from
export interface IOptInOptionsParams {
  available: IOptInOption[]
  selected: IOptInOption
}

export interface IOptInOption {
  id: string
  value: string
  selected?: boolean
}

// Details about the state of the criteria selected for a promo
export interface ICriteriaState {
  fulfilled: boolean
  ratio: number // the fulfillment ratio indicating the progress of a prootion for a certain user. Possible values, between 0 and 1
  category: CriteriaCategory
  tierDefinition?: ITierDefinition
  children?: ICriteriaState[] // list of criteria in a compound criteria
  params?: {
    gauge?: IGaugeParams
    value?: IValueParams
    optInOptions?: IOptInOptionsParams
    enhancedSelection?: IEnchancedSelection[]
  }
}

export interface IEnchancedSelection {
  selection: IMarketSelection[]
  enhancedOdd: number
}

export interface IMarketSelection {
  marketId: string
  selectionId: string
}

export interface IGaugeParams {
  current: number
  target: number
  minValue: number
  type: string
}

export interface IValueParams {
  type: string
  value: string
  description: string
}

export interface IRewardParams {
  gauge: IGaugeParams
  value: IValueParams
}

export interface IRewardState {
  ratio: number // The fulfillment ratio indicating the progress for this reward. This value is within [0..1] range.
  params?: {
    gauge?: IGaugeParams
    value?: IValueParams
  }
}

export enum RewardType {
  CashToMainWallet = 'CASH_TO_MAIN_WALLET',
  Void = 'VOID',
  TransferToMain = 'TRANSFER_TO_MAIN',
  FreeBetBonus = 'BONUS_AWARDING',
  FreeSpins = 'CAMPAIGN_FREE_SPINS',
}

export interface IVariableAmount {
  maxAmount: number
  ratio: number
}

export interface IRewardDefinition {
  type: RewardType
  value: number
  tierDefinition?: ITierDefinition
  awarded?: boolean
  state?: IRewardState
  products?: string[]
  variableAmount?: IVariableAmount
}

//details about the included/excluded games and providers based on the current customer-promotion relation
export interface IGamesDefinition {
  games: GamesIdentifier[]
  gamesRestriction: RestrictionType
  gamesProviders: GamesIdentifier[]
  providerRestriction: RestrictionType
}

// ecapsulates various details regarding customer-promotion relationship
export interface ICustomerPromotionState {
  steps?: IStep[]
  eligibility: IEligibility
  criteriaState?: ICriteriaState
  reward?: IRewardDefinition
  games?: IGamesDefinition
  promoStateExpiryDate: string // expiration date of a promotion for a customer
  fulfillmentEndDate?: string // Indicates the date by which the user can fulfill the promotion criteria.
  optInState: OptInState
}
