import React from 'react'
import styled from 'styled-components'

import { useCountDownTimer } from '../../../hooks/useCountDownTimer'

export interface TimeLeftProps {
  promoStateExpiryDate: string
  onTimeExpire?: () => void
}

const TimeLeftStyle = styled.p`
  ${({ theme }) => `
    margin-top: 0;
    font-family: ${theme.timeLeft.text.fontFamily};
    font-size:  ${theme.timeLeft.text.size};
    color:  ${theme.timeLeft.text.color};
    weight:  ${theme.timeLeft.text.weight};
    text-transform: uppercase;
    letter-spacing: 1px;
  `}
`

export const TimeLeft: React.FC<TimeLeftProps> = ({
  promoStateExpiryDate,
  onTimeExpire,
}) => {
  const timeLeft = useCountDownTimer(promoStateExpiryDate, onTimeExpire)

  return (
    <span>
      {timeLeft && (
        <TimeLeftStyle data-test-id="promotion-time-left">
          Expires in {timeLeft?.expirationDate}{' '}
        </TimeLeftStyle>
      )}
    </span>
  )
}
