import styled, { DefaultTheme, keyframes, css } from 'styled-components'
import { LoaderType } from '../../../types/ThemeType'

const dotPulse = () =>
  keyframes`
  0% {
    opacity: 0.25;
  }
  50%, {
    opacity: 0.50
  }
  100% {
    opacity: 1
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const LoaderStyles = styled.span<{
  theme: DefaultTheme
  style?: LoaderType
}>`
  ${({ theme, style }) => css`
    display: inline-flex;
    ${style?.boxPadding?.all ? `padding: ${style?.boxPadding.all};` : ''}
    span {
      width: ${style?.size || theme.loader.size || '1rem'};
      height: ${style?.size || theme.loader.size || '1rem'};
      margin-left: ${style?.gap || theme.loader.gap || '10px'};
      border-radius: 50%;
      display: block;
      background-color: ${style?.color || theme.loader.color};
      animation: ${dotPulse()} 0.6s infinite alternate;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  `}
`
