import React, { useContext } from 'react'

import { ButtonProps, Button } from '../Button/Button'
import { CallToActionType, Promotion } from '../../../types'
import { AppStateContext } from '../../../contexts/AppContext'
import { LoaderType } from '../../../types'
import { Loader } from '../Loader/Loader'
import { getParameterFromURL } from '../../../utils/getParameterFromUrl'
import {
  INCENTIVE_GAMES_RGI,
  REGION_PARAM_KEY,
  RGI,
  THEME_PARAM,
} from '../../../constants'
import { addRegionToUrl } from '../../../utils/regionURL'
import { useTheme } from 'styled-components'

export type CallToActionProps = ButtonProps & {
  callToAction: Promotion['callToAction']
  customerPromotionState: Promotion['customerPromotionState']
  loading?: boolean
  loaderStyle?: LoaderType
  promoCode?: string
  disabled?: boolean
  dataTestId?: string
  registrationCode?: string
}

export const CallToAction: React.FC<CallToActionProps> = ({
  buttonType,
  width,
  full,
  loading,
  callToAction,
  onClick,
  loaderStyle,
  promoCode,
  dataTestId,
  registrationCode,
  disabled,
  customerPromotionState,
}) => {
  const {
    routeConfig: {
      routes: { login: loginRoute, join: joinRoute },
    },
    isLoggedIn,
    anchorCallback,
    location,
  } = useContext(AppStateContext)

  const { productTheme } = useTheme()

  const loginOrRegistrationRoute = registrationCode
    ? joinRoute.href
    : loginRoute.href
  const hasReward = Boolean(customerPromotionState?.reward)
  const isRewardMachine =
    getParameterFromURL(callToAction.url, RGI) === INCENTIVE_GAMES_RGI
  let rewardMachineUrl = callToAction.url

  if (isRewardMachine) {
    if (!getParameterFromURL(callToAction.url, REGION_PARAM_KEY)) {
      rewardMachineUrl = addRegionToUrl(callToAction.url, location)
    }
    if (!getParameterFromURL(callToAction.url, THEME_PARAM)) {
      rewardMachineUrl = `${rewardMachineUrl}&theme=${productTheme}`
    }
  }

  return (
    <React.Fragment>
      {(!isLoggedIn && hasReward && loginOrRegistrationRoute) ||
      (!isLoggedIn && isRewardMachine) ? (
        <Button
          buttonType={buttonType}
          width={width}
          href={loginOrRegistrationRoute}
          promoCode={promoCode}
          dataTestId={dataTestId}
          anchorCallback={anchorCallback}
        >
          {callToAction.text}
        </Button>
      ) : callToAction.action === CallToActionType.OPT_IN ? (
        <Button
          buttonType={buttonType}
          width={width}
          onClick={onClick}
          disabled={loading || disabled}
          promoCode={promoCode}
          dataTestId={dataTestId}
        >
          {loading ? <Loader style={loaderStyle} /> : callToAction.text}
        </Button>
      ) : (
        <Button
          buttonType={buttonType}
          full={full}
          width={width}
          href={isRewardMachine ? rewardMachineUrl : callToAction.url}
          promoCode={promoCode}
          dataTestId={dataTestId}
        >
          {callToAction.text}
        </Button>
      )}
    </React.Fragment>
  )
}
