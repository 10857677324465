import React, { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { PromoDetailsContext } from '../../../contexts/PromotionDetailsContext'
import { Promotion } from '../../../types/Promotion'
import { Image } from '../../atoms/Image/Image'
import { Steps } from '../../atoms/Steps/Steps'
import { CallToAction } from '../../atoms/CallToAction/CallToAction'
import { PromoBannerStyles } from '../PromotionList/PromotionCard/PromotionCard'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { getShouldShowProgress } from '../../../utils/geOptInStates'
import { getImageDetails } from '../../../utils/getImageDetails'
import { getSteps } from '../../../utils/getSteps'
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions'

import {
  PromotionStyle,
  ContainerStyles,
  HeadingStyle,
  DetailsText,
  CtaWrapperStyle,
  LandingBadgeStyles,
} from './PromotionDetailsStyles'
import { getBadgeType } from '../../../utils/getBadgeDetails'
import { Badge } from '../../atoms/Badge/Badge'
import { BadgeType, RouteHandlerTypes } from '../../../types'
import { AppStateContext } from '../../../contexts/AppContext'
import { OptInOptions } from '../OptInOptions/OptInOptions'
import { PROMO_DETAILS_ACTIONS } from '../../../reducers/promotionDetailsReducer'
import { getOptInOptions } from '../../../utils/getOptInOptions'
import { getPromoDescriptionHtml } from '../../../utils/getPromoDescriptionHtml'
import { setPageTitle } from '../../../utils/setPageTitle'
import { Breadcrumbs } from '../../atoms'
import { PromoTimer } from '../PromoTimer/PromoTimer'
import { useCountDownTimer } from '../../../hooks/useCountDownTimer'

export interface PromotionDetailsProps {
  promotion: Promotion
}

const BreadcrumbsWrapper = styled.div`
  ${({ theme }) => `
  display: none;
  padding: 16px 16px 0;
  margin-bottom: 0px;
  ${
    theme.mediaQueries.tablet.maxWidth &&
    `@media (max-width: ${theme.mediaQueries.tablet.maxWidth}) {
          display: block;
      }`
  }
  `}
`

export const PromotionDetails: React.FC<PromotionDetailsProps> = ({
  promotion,
}) => {
  const {
    name,
    title,
    promoCode,
    description,
    images,
    termsAndConditions,
    callToAction,
    customerPromotionState,
    moreInfo,
    showTimeLeft,
    optInStartDate,
  } = promotion
  const { promoStateExpiryDate, fulfillmentEndDate } = customerPromotionState

  const filteredImages = images.filter(({ width }) =>
    [1400, 700].includes(width)
  )
  const { defaultLandingPageImageUrl, promotionDetailsPageTitle } = useContext(
    AppStateContext
  )
  const { url, set } = getImageDetails(
    filteredImages,
    defaultLandingPageImageUrl
  )
  const {
    dispatch,
    state: { isLoadingOptIn, isReloadPromotion, isCtaDisabled },
  } = useContext(PromoDetailsContext)

  const {
    landing: { buttonLoader },
    mediaQueries: {
      mobile: { maxWidth },
    },
  } = useContext(ThemeContext)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    if (promotionDetailsPageTitle && title) {
      setPageTitle(promotionDetailsPageTitle, title)
    }
    window.addEventListener('resize', updateScreenWidth)
    return () => {
      window.addEventListener('resize', updateScreenWidth)
    }
  }, [])
  const updateScreenWidth = (event: UIEvent) => {
    const targetWindow = event.target as Window
    setWindowWidth(targetWindow.innerWidth)
  }

  const onCtaButtonClicked = () => {
    dispatch({
      type: PROMO_DETAILS_ACTIONS.OPT_IN,
      payload: promoCode,
    })
  }

  const badge: BadgeType | null = getBadgeType(promotion)
  const steps = getSteps(promotion)
  const optInOptions = getOptInOptions(promotion.customerPromotionState)
  const shouldShowProgressBar = getShouldShowProgress(
    promotion.customerPromotionState
  )
  const isMobile = windowWidth <= parseInt(maxWidth || '')
  const ctaSection = (
    <CtaWrapperStyle data-test-id="promotion-details-view-cta">
      <CallToAction
        buttonType="primary"
        width={isMobile ? '100%' : '40%'}
        callToAction={callToAction}
        onClick={onCtaButtonClicked}
        disabled={isReloadPromotion || isLoadingOptIn || isCtaDisabled}
        loading={isReloadPromotion || isLoadingOptIn}
        loaderStyle={buttonLoader}
        customerPromotionState={customerPromotionState}
        promoCode={promotion.promoCode}
        dataTestId={`promo-details-cta-${promotion.promoCode}`}
        registrationCode={promotion.registrationCode}
      />
    </CtaWrapperStyle>
  )

  if (
    optInOptions &&
    !optInOptions.find((el) => el.selected) &&
    !isCtaDisabled
  ) {
    dispatch({ type: PROMO_DETAILS_ACTIONS.DISABLE_OPT_IN_CTA })
  }

  const { hideBreadcrumbs } = useContext(AppStateContext)

  const handleTimeExpire = () => {
    window.location.reload()
  }

  useCountDownTimer(fulfillmentEndDate, () => {
    window.location.reload()
  })

  return (
    <PromotionStyle data-test-id="promotion-details">
      <PromoBannerStyles>
        <Image url={url} set={set} errorImageUrl={defaultLandingPageImageUrl} />
        {!!badge && (
          <LandingBadgeStyles>
            <Badge label={badge.label} type={badge.type} />
          </LandingBadgeStyles>
        )}
      </PromoBannerStyles>
      {!hideBreadcrumbs && (
        <BreadcrumbsWrapper>
          <Breadcrumbs
            hasWrapper={true}
            displayAlways={true}
            page={RouteHandlerTypes.VIEW_PROMOTION}
          />
        </BreadcrumbsWrapper>
      )}
      <ContainerStyles>
        <HeadingStyle data-test-id="promotion-details-view-title">
          {name}
        </HeadingStyle>
        {showTimeLeft && (
          <PromoTimer
            onStateExpire={handleTimeExpire}
            optInStartDate={optInStartDate}
            stateExpiryDate={promoStateExpiryDate}
          />
        )}
        <DetailsText
          dangerouslySetInnerHTML={{
            __html: getPromoDescriptionHtml(description),
          }}
          data-test-id="promotion-details-view-body"
        />
        {shouldShowProgressBar && customerPromotionState.criteriaState && (
          <ProgressBar ratio={customerPromotionState.criteriaState.ratio} />
        )}
        {steps && steps.length > 0 && <Steps steps={steps} />}
        {optInOptions && optInOptions.length && (
          <OptInOptions options={optInOptions}></OptInOptions>
        )}
        {moreInfo && (
          <DetailsText
            dangerouslySetInnerHTML={{
              __html: getPromoDescriptionHtml(moreInfo),
            }}
          />
        )}
        {!isMobile && ctaSection}
        {termsAndConditions && (
          <TermsAndConditions
            promoCode={promotion.promoCode}
            termsAndConditions={termsAndConditions}
          />
        )}
      </ContainerStyles>
      {isMobile && ctaSection}
    </PromotionStyle>
  )
}
