import { Promotion } from '../types'

export enum PROMO_DETAILS_ACTIONS {
  SET_OPT_IN_PARAMS = 'SET_OPT_IN_PARAMS',
  LOAD_PROMO = 'LOAD_PROMO',
  LOAD_PROMO_SUCCESS = 'LOAD_PROMO_SUCCESS',
  LOAD_PROMO_ERROR = 'LOAD_PROMO_ERROR',
  OPT_IN = 'OPT_IN',
  OPT_IN_SUCCESS = 'OPT_IN_SUCCESS',
  OPT_IN_ERROR = 'OPT_IN_ERROR',
  SELECT_OPT_IN_OPTION = 'SELECT_OPT_IN_OPTION',
  DISABLE_OPT_IN_CTA = 'DISABLE_OPT_IN_CTA',
}

export interface PromotionDetailsAction {
  type: PROMO_DETAILS_ACTIONS
  payload?: any
}

export interface PromotionDetailsState {
  isLoadingPromotion: boolean
  isReloadPromotion?: boolean
  isLoadingOptIn: boolean
  isCtaDisabled: boolean
  optInParam?: string
  promotion?: Promotion
}

export const promotionDetailsReducer = (
  state: PromotionDetailsState,
  action: PromotionDetailsAction
) => {
  const { type, payload } = action

  switch (type) {
    case PROMO_DETAILS_ACTIONS.LOAD_PROMO: {
      return {
        ...state,
        isLoadingPromotion: true,
      }
    }

    case PROMO_DETAILS_ACTIONS.LOAD_PROMO_SUCCESS: {
      return {
        ...state,
        promotion: payload,
        isLoadingPromotion: false,
        isReloadPromotion: false,
      }
    }

    case PROMO_DETAILS_ACTIONS.LOAD_PROMO_ERROR: {
      return {
        ...state,
        isLoadingPromotion: false,
      }
    }

    case PROMO_DETAILS_ACTIONS.OPT_IN: {
      return {
        ...state,
        isLoadingOptIn: true,
      }
    }

    case PROMO_DETAILS_ACTIONS.OPT_IN_SUCCESS: {
      return {
        ...state,
        isLoadingOptIn: false,
        isReloadPromotion: true,
      }
    }

    case PROMO_DETAILS_ACTIONS.OPT_IN_ERROR: {
      return {
        ...state,
        isLoadingOptIn: false,
      }
    }

    case PROMO_DETAILS_ACTIONS.SELECT_OPT_IN_OPTION: {
      return {
        ...state,
        optInParam: payload,
        isCtaDisabled: false,
      }
    }

    case PROMO_DETAILS_ACTIONS.DISABLE_OPT_IN_CTA: {
      return {
        ...state,
        isCtaDisabled: true,
      }
    }

    default:
      return state
  }
}
