import { Promotion } from '../types/Promotion'

export enum PROMO_LIST_ACTIONS {
  LOAD_PROMOS = 'LOAD_PROMOS',
  LOAD_PROMOS_SUCCESS = 'LOAD_PROMOS_SUCCESS',
  LOAD_PROMOS_FAIL = 'LOAD_PROMOS_FAIL',
  GET_PROMO = 'GET_PROMO',
  GET_PROMO_SUCCESS = 'GET_PROMO_SUCCESS',
  GET_PROMO_FAIL = 'GET_PROMO_FAIL',
  OPT_IN = 'OPT_IN',
  OPT_IN_SUCCESS = 'OPT_IN_SUCCESS',
  OPT_IN_FAIL = 'OPT_IN_FAIL',
}

export interface PromotionListAction {
  type: PROMO_LIST_ACTIONS
  payload?: any
}

export interface PromotionListState {
  promos: Promotion[]
  queue: Promotion['promoCode'][]
  optInCode?: string
  singlePromoCode?: string
  ongoingOptIn: Promotion['promoCode'][]
  ongoingPromotionCalls: Promotion['promoCode'][]
  loadingPromotions: boolean
  totalPromotions: number
  page: number
}

export const promotionListReducer = (
  state: PromotionListState,
  action: PromotionListAction
) => {
  const { type, payload } = action
  switch (type) {
    case PROMO_LIST_ACTIONS.GET_PROMO: {
      const ongoingPromotionCalls = [...state.ongoingPromotionCalls, payload]
      return {
        ...state,
        ongoingPromotionCalls,
        singlePromoCode:
          ongoingPromotionCalls[ongoingPromotionCalls.length - 1],
      }
    }
    case PROMO_LIST_ACTIONS.GET_PROMO_SUCCESS: {
      const ongoingPromotionCalls = state.ongoingPromotionCalls.filter(
        (el) => el !== payload?.promoCode
      )

      const promos = state.promos?.map((el) => {
        if (el.promoCode === payload?.promoCode) {
          return payload
        }
        return el
      })

      return {
        ...state,
        ongoingPromotionCalls,
        promos,
      }
    }
    case PROMO_LIST_ACTIONS.GET_PROMO_FAIL: {
      return {
        ...state,
        ongoingPromotionCalls: state.ongoingPromotionCalls.filter(
          (el) => el !== payload?.promoCode
        ),
      }
    }

    case PROMO_LIST_ACTIONS.LOAD_PROMOS: {
      return {
        ...state,
        loadingPromotions: true,
        page: state.page + 1,
      }
    }

    case PROMO_LIST_ACTIONS.LOAD_PROMOS_SUCCESS: {
      return {
        ...state,
        totalPromotions: payload?.totalPromotions,
        promos: [...state.promos, ...payload?.promos],
        loadingPromotions: false,
      }
    }

    case PROMO_LIST_ACTIONS.LOAD_PROMOS_FAIL: {
      return {
        ...state,
        loadingPromotions: false,
        page: state.page - 1,
      }
    }

    case PROMO_LIST_ACTIONS.OPT_IN: {
      const ongoing =
        state.ongoingOptIn.length >= 2
          ? state.ongoingOptIn
          : [...state.ongoingOptIn, payload]
      return {
        ...state,
        optInCode: ongoing[ongoing.length - 1],
        queue: [...state.queue, payload],
        ongoingOptIn: ongoing,
      }
    }

    case PROMO_LIST_ACTIONS.OPT_IN_SUCCESS: {
      const ongoing = state.ongoingOptIn.filter((el) => el !== payload)
      const queue = state.queue.filter((el) => el !== payload)
      if (queue.length) {
        queue.forEach((code) => {
          !ongoing.find((el) => el == code) && ongoing.push(code)
        })
      }

      return {
        ...state,
        queue,
        optInCode: ongoing[ongoing.length - 1],
        ongoingOptIn: ongoing,
      }
    }

    case PROMO_LIST_ACTIONS.OPT_IN_FAIL: {
      //TODO add error opt in info
      const ongoing = state.ongoingOptIn.filter((el) => el !== payload)
      const queue = state.queue.filter((el) => el !== payload)
      if (queue.length) {
        queue.forEach((code) => {
          !ongoing.find((el) => el == code) && ongoing.push(code)
        })
      }
      return {
        ...state,
        queue,
        optInCode: ongoing[ongoing.length - 1],
        ongoingOptIn: ongoing,
      }
    }
    default:
      return state
  }
}
