import { AppConfig } from '../types'
import {
  DEFAULT_LANDING_PAGE_IMAGE,
  DEFAULT_PROMO_HUB_IMAGE,
} from '../constants'

export const getDefaultLandingPageImageUrl = (config: AppConfig) => {
  return config.defaultLandingPageImageUrl || DEFAULT_LANDING_PAGE_IMAGE
}

export const getDefaultPromoHubImageUrl = (config: AppConfig) => {
  return config.defaultPromoHubImageUrl || DEFAULT_PROMO_HUB_IMAGE
}
