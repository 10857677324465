import { useState, useEffect, useCallback } from 'react'
import { intervalToDuration, isAfter } from 'date-fns'

interface State {
  expirationDate: string | null
}

export const getTimeLeft = (endTime: string) => {
  const start = new Date(endTime)
  const end = new Date()

  if (isAfter(end, start)) {
    return null
  }

  const { years, months, days, hours, minutes, seconds } = intervalToDuration({
    start,
    end,
  })
  const friendlyTimeString =
    years && years >= 1
      ? `${years}y ${months}m`
      : months && months >= 1
      ? `${months}m ${days}d`
      : days && days >= 1
      ? `${days}d ${hours}h`
      : hours && hours >= 1
      ? `${hours}h ${minutes}m`
      : minutes && minutes >= 1
      ? `${minutes}m ${seconds || 0}s`
      : seconds && seconds >= 1
      ? `${seconds}s`
      : ''

  return friendlyTimeString
}

export const useCountDownTimer = (
  date: string = new Date().toISOString(),
  onTimeExpire?: () => void
) => {
  const [expirationState, setExpirationState] = useState<State | null>(null)

  const updateTimer = useCallback(() => {
    const timeLeft = getTimeLeft(date)
    if (!timeLeft) {
      setExpirationState(null)
      if (onTimeExpire) onTimeExpire()
    } else if (expirationState?.expirationDate !== timeLeft) {
      setExpirationState({ expirationDate: timeLeft })
    }
  }, [expirationState, date])

  useEffect(() => {
    const timeLeft = getTimeLeft(date)
    let intervalId: NodeJS.Timeout
    if (timeLeft) intervalId = setInterval(updateTimer, 1000, date)
    return () => clearInterval(intervalId)
  }, [expirationState, date])

  return expirationState
}
