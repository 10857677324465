import React, { useContext, useEffect } from 'react'
import { PromotionList } from './PromotionList'
import { AppStateContext } from '../../../contexts/AppContext'
import { PromoListContext } from '../../../contexts/PromotionListContext'
import { useAllPromotionsData } from '../../../hooks/useAllPromotionsData'
import { useSinglePromotionData } from '../../../hooks/useSinglePromotionData'
import { useOptInPromotion } from '../../../hooks/useOptInPromotion'
import { getIsFreeSpinsReward } from '../../../utils/getIsFreeSpinsReward'

export const PromotionListState: React.FC = () => {
  const context = useContext(AppStateContext)
  const {
    state: { page, optInCode, singlePromoCode, promos },
  } = useContext(PromoListContext)
  useAllPromotionsData(page, context.placementQueryConfig)
  useSinglePromotionData(singlePromoCode)

  const { optIn } = useOptInPromotion()
  useEffect(() => {
    if (optInCode) {
      optIn(
        optInCode,
        getIsFreeSpinsReward(promos.find((el) => el.promoCode === optInCode))
      )
    }
  }, [optInCode])
  return (
    <React.Fragment>
      <PromotionList />
    </React.Fragment>
  )
}
