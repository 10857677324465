import React from 'react'
import styled from 'styled-components'

export interface CardTitleProps {}

const CardTitleStyles = styled.h2`
  ${({ theme }) => `
    font-size: ${theme.card.title.text.size};
    font-weight: ${theme.card.title.text.weight};
    font-family: ${theme.card.title.text.fontFamily || 'inherit'};
    color: ${theme.card.title.text.color};
    margin-bottom: 16px;
  `}
`

export const CardTitle: React.FC<CardTitleProps> = ({ children }) => {
  return (
    <CardTitleStyles data-test-id="promotion-card-title">
      {children}
    </CardTitleStyles>
  )
}
