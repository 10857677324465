import React from 'react'
import styled from 'styled-components'

export interface RadioButtonProps {
  label?: string
  id: string
  checked?: boolean
  onChange: (id: string) => void
}

const RadioButtonMask = styled.label<{ selected: boolean }>`
  ${({
    selected,
    theme: {
      radioButton: {
        button: { checked, unchecked },
      },
    },
  }) =>
    `  
    top: 0;
    position: absolute;
    left: 0;
    height: 17px;
    width: 17px;
    border-color: ${unchecked.border.color};
    border-width: ${unchecked.border.thickness};
    border-style: ${unchecked.border.style};
    background-color: ${unchecked.background.color};
    border-radius: ${unchecked.border.radius || '50%'};
    cursor: pointer;
    &::after {
      top: 5px;
      left: 5px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: ${unchecked.background.color};
      content: '';
      position: absolute;
    }
    ${
      selected &&
      `
      background-color: ${checked.background.color};
      border-width: 0px;
    `
    }
    `}
`

export const RadioButtonLabel = styled.span`
  ${({
    theme: {
      radioButton: { text },
    },
  }) => `
    color: ${text.color};
    size: ${text.size};
    font-weight: ${text.weight};
    font-family: ${text.fontFamily || 'inherit'};
`}
`

const RadioButtonContainer = styled.div`
  position: relative;
  padding-left: 26px;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
`

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  id,
  checked = false,
  onChange,
}) => {
  const handleOnClick = () => onChange(id)
  return (
    <RadioButtonContainer onClick={handleOnClick}>
      <RadioButtonMask
        selected={checked}
        data-test-id={`opt-in-option-button-${id}`}
      />
      {label && (
        <RadioButtonLabel data-test-id={`opt-in-option-label-${id}`}>
          {label}
        </RadioButtonLabel>
      )}
    </RadioButtonContainer>
  )
}
