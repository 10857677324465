import DOMPurify from 'dompurify'

export const getHtmlWithPurgedStyles = (
  body: string,
  elemHandler?: (el: Element) => void
) => {
  const divElement = document.createElement('div')
  divElement.innerHTML = DOMPurify.sanitize(body)

  const elements = divElement.getElementsByTagName('*')
  for (let i = 0; i < elements.length; i++) {
    const styles = (elements[i].getAttribute('style') || '')
      .split(';')
      .map((style) => style.trim())
      .filter(
        (style) =>
          style.includes('font-weight') ||
          style.includes('font-style') ||
          style.includes('text-decoration')
      )
    if (styles.length > 0) {
      elements[i].setAttribute(
        'style',
        `${styles.join(';')}${styles.length > 0 ? ';' : ''}`
      )
    } else {
      elements[i].removeAttribute('style')
    }
    elemHandler?.(elements[i])
  }
  if (elements.length < 1) {
    divElement.innerHTML = `<p>${divElement.innerHTML}</p>`
  }

  return divElement.innerHTML
}
