import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../../atoms/Button/Button'
import { Promotion } from '../../../../types/Promotion'

import {
  TermsStyle,
  TermsTitleStyle,
  TermsContentStyle,
  TermsGradient,
} from './TermsAndConditionsStyles'
import { getHtmlWithPurgedStyles } from '../../../../utils/getHtmlWithPurgedStyles'
import { ChevronUp } from '../../../../assets/icons/ChevronUp'
import { ChevronDown } from '../../../../assets/icons/ChevronDown'
import { useTheme } from 'styled-components'

export interface TermsAndConditionsProps {
  promoCode: string
  termsAndConditions: Promotion['termsAndConditions']
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
  promoCode,
  termsAndConditions,
}) => {
  const [termsHeight, setTermsHeight] = useState<number | null>(null)
  const [showAllTerms, setShowAllTerms] = useState(false)

  const termsConditionsRef = useRef<HTMLElement>(null)
  const { button: buttonStyle } = useTheme()

  useEffect(() => {
    if (termsConditionsRef.current) {
      setTermsHeight(termsConditionsRef.current.scrollHeight)
    }
  }, [termsConditionsRef])
  return (
    <TermsStyle
      hideContent={!showAllTerms && Boolean(termsHeight && termsHeight > 300)}
      ref={termsConditionsRef}
      data-test-id="promotion-terms"
    >
      <TermsTitleStyle data-test-id="promotion-terms-title">
        Terms {`&`} Conditions
      </TermsTitleStyle>
      <TermsContentStyle
        dangerouslySetInnerHTML={{
          __html: getHtmlWithPurgedStyles(termsAndConditions.full),
        }}
        data-test-id="promotions-terms-body"
      />
      {Boolean(termsHeight && termsHeight > 300) && (
        <TermsGradient showAllTerms={showAllTerms}>
          <Button
            buttonType="text"
            dataTestId="promotion-terms-button"
            onClick={() => {
              setShowAllTerms(!showAllTerms)
            }}
            promoCode={promoCode}
          >
            {showAllTerms ? (
              <>
                See less &nbsp; <ChevronUp />
              </>
            ) : (
              <>
                See more &nbsp; <ChevronDown />
              </>
            )}
          </Button>
        </TermsGradient>
      )}
    </TermsStyle>
  )
}
