import styled, { DefaultTheme } from 'styled-components'

export const ErrorScreenStyle = styled.div<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    ${
      theme.errorScreen.background.image &&
      `
      background-image: url(${theme.errorScreen.background.image.url});
      background-size: ${theme.errorScreen.background.image.size};
      background-repeat: ${theme.errorScreen.background.image.repeat};
      background-position: ${theme.errorScreen.background.image.position};
    `
    }
    background-color: ${theme.errorScreen.background.color || 'transparent'};
    padding-top: ${theme.errorScreen.padding.top}
  `}
`

export const ErrorScreenTitleStyle = styled.h2<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    font-size: ${theme.errorScreen.title.size};
    text-align: ${theme.errorScreen.title.align || 'center'};
    font-weight: ${theme.errorScreen.title.weight};
    color: ${theme.errorScreen.title.color};
    margin-bottom: 8px;
  `}
`

export const ErrorScreenContentStyle = styled.p<{ theme: DefaultTheme }>`
  ${({ theme }) => `
  font-size: ${theme.errorScreen.message.size};
  text-align: ${theme.errorScreen.message.align || 'center'};
  font-weight: ${theme.errorScreen.message.weight};
  color: ${theme.errorScreen.message.color};
`}
`

export const ErrorScreenContainer = styled.main<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    height: 100%;
    max-width: 1000px;
    margin-right: ${theme.errorScreen.container?.margin?.right};
    margin-left: ${theme.errorScreen.container?.margin?.left};
    padding-top: ${theme.errorScreen.container?.padding?.top};
    padding-bottom: ${theme.errorScreen.container?.padding?.bottom};
    background-color: ${theme.errorScreen.container?.background?.color};
  `}
`

export const ErrorScreenWrapper = styled.main<{
  theme: DefaultTheme
}>`
  ${({ theme }) => `
      height: 50vh;
      max-width: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.errorScreen.container?.margin?.right};
      margin-left: ${theme.errorScreen.container?.margin?.left};
      padding-top: ${theme.errorScreen.container?.padding?.top};
      padding-bottom: ${theme.errorScreen.container?.padding?.bottom};
      background-color: ${theme.errorScreen.container?.background?.color};
    `}
`
