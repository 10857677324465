import React from 'react'

export const ChevronLeft: React.FC<{
  fill: string
}> = ({ fill }) => {
  return (
    <svg
      width="7"
      height="13"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.853 8.85225C5.048 8.65725 5.048 8.34025 4.853 8.14525L1.207 4.49925L4.853 0.85325C5.048 0.65825 5.048 0.34125 4.853 0.14625C4.658 -0.04875 4.341 -0.04875 4.146 0.14625L0.146 4.14625C0.048 4.24425 0 4.37225 0 4.50025C0 4.62825 0.049 4.75625 0.146 4.85425L4.146 8.85425C4.341 9.04725 4.657 9.04725 4.853 8.85225Z"
        fill={fill}
      />
    </svg>
  )
}
