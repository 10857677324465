import React, { useContext } from 'react'
import { PromoListContextProvider } from '../../../contexts/PromotionListContext'
import { PromotionListState } from '../../molecules/PromotionList/PromotionListState'
import { Breadcrumbs } from '../../atoms/Breadcrumbs/Breadcrumbs'
import { AppStateContext } from '../../../contexts/AppContext'

export interface PromotionsViewProps {}

export const PromotionsView: React.FC<PromotionsViewProps> = () => {
  const { hideBreadcrumbs } = useContext(AppStateContext)

  return (
    <div>
      <PromoListContextProvider>
        {!hideBreadcrumbs && <Breadcrumbs displayAlways={true} />}
        <PromotionListState />
      </PromoListContextProvider>
    </div>
  )
}
