export enum BADGE {
  NOT_AVAILABLE = 'notAvailable',
  EXPIRED = 'expired',
  BONUS_AWARDED = 'bonusAwarded',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed',
  COMING_SOON = 'comingSoon',
  BONUS_USED = 'bonusUsed',
  QUALIFIED = 'qualified',
  DEFAULT = 'default',
}

export interface BadgeType {
  type: BADGE
  label: string
}

export const notAvailable: BadgeType = {
  type: BADGE.NOT_AVAILABLE,
  label: 'Not Available',
}

export const expired: BadgeType = {
  type: BADGE.EXPIRED,
  label: 'Expired',
}

export const bonusAwarded: BadgeType = {
  type: BADGE.BONUS_AWARDED,
  label: 'Bonus Awarded',
}
export const bonusUsed: BadgeType = {
  type: BADGE.BONUS_USED,
  label: 'Bonus Used',
}

export const accepted: BadgeType = {
  type: BADGE.ACCEPTED,
  label: 'Accepted',
}

export const completed: BadgeType = {
  type: BADGE.COMPLETED,
  label: 'Completed',
}

export const comingSoon: BadgeType = {
  type: BADGE.COMING_SOON,
  label: 'Coming Soon',
}

export const qualified: BadgeType = {
  type: BADGE.QUALIFIED,
  label: 'Qualified',
}
