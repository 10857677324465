import React from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'

import { BADGE } from '../../../types/BadgeTypes'

export interface BadgeProps {
  label: string
  type: BADGE
}

export const BadgeStyles = styled.div<{ theme: DefaultTheme; type: BADGE }>`
  ${({ type, theme }) => `
    background-color: ${theme.badge[type].background.color};
    color: ${theme.badge[type].text.color};
    ${
      theme.badge[type].text.fontFamily &&
      `font-family: ${theme.badge[type].text.fontFamily}`
    };
    display: inline-block;
    padding: 6px 8px;
    border-radius: 2px;
    font-size: 12px;
    letter-spacing: 1px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
`}
`

export const Badge: React.FC<BadgeProps> = ({ label, type }) => {
  const { badge } = useTheme()

  return (
    <BadgeStyles
      type={badge[type] ? type : BADGE.DEFAULT}
      data-test-id={`promotion-status-badge-${type}`}
    >
      {label}
    </BadgeStyles>
  )
}
