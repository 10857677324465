import React from 'react'
import styled from 'styled-components'

const CardStyles = styled.div`
  ${({
    theme: {
      card: { container },
    },
  }) => `
  background-color: ${container.background.color};
  box-shadow: ${container.boxShadow};
  ${
    container.border &&
    `border-width: ${container.border.thickness};
    border-style: ${container.border.style};
    border-color: ${container.border.color};
    border-radius: ${container.border.radius};`
  };
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
`

export const CardContentStyles = styled.div`
  position: relative;
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
`

export const Card: React.FC = ({ children }) => {
  return <CardStyles>{children}</CardStyles>
}
