import React from 'react'
import {
  OverlayItemDescriptionStyle,
  OverlayItemStyle,
  OverlayItemTitleStyle,
} from '../OverlayItemsStyles'
import { useCountDownTimer } from '../../../../hooks/useCountDownTimer'

export interface TimeLeftProps {
  promoStateExpiryDate: string
}

export const TimeLeft: React.FC<TimeLeftProps> = ({ promoStateExpiryDate }) => {
  const timeLeft = useCountDownTimer(promoStateExpiryDate)
  return (
    <OverlayItemStyle
      data-test-id="promotion-overlay-time-left"
      textAlign="right"
    >
      <OverlayItemTitleStyle data-test-id="promotion-time-left-title">
        Time Left
      </OverlayItemTitleStyle>
      <OverlayItemDescriptionStyle data-test-id="promotion-time-left-description">
        {timeLeft?.expirationDate}
      </OverlayItemDescriptionStyle>
    </OverlayItemStyle>
  )
}
