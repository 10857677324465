import React from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { useProductTheme } from '../hooks/useProductTheme'

import { AppName } from '../types/AppName'

interface ThemeWrapperProps {
  appName: AppName
  themeOverrides?: Partial<DefaultTheme>
}

export const ThemeWrapper: React.FC<ThemeWrapperProps> = ({
  children,
  appName,
  themeOverrides,
}) => {
  const { theme } = useProductTheme(appName, themeOverrides)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
