import React from 'react'

import { AppContextProvider } from '../contexts/AppContext'
import { AppConfig } from '../types'
import { ThemeWrapper } from './ThemeWrapper'
import { getAxios } from './HttpService'
import {
  getDefaultLandingPageImageUrl,
  getDefaultPromoHubImageUrl,
} from './getDefaultImage'

export interface SharedWrapperProps {
  config: AppConfig
}

export const SharedWrapper: React.FC<SharedWrapperProps> = ({
  children,
  config,
}) => {
  return (
    <AppContextProvider
      appName={config.appName}
      domain={config.domain}
      location={config.location}
      routeConfig={config.routeConfig}
      baseUrl={config.baseUrl}
      promoCode={config.promoCode}
      placementQueryConfig={config.placementQueryConfig}
      axios={getAxios(config)}
      isLoggedIn={config.isLoggedIn}
      events={config.events}
      anchorCallback={config.anchorCallback}
      defaultLandingPageImageUrl={getDefaultLandingPageImageUrl(config)}
      defaultPromoHubImageUrl={getDefaultPromoHubImageUrl(config)}
      promotionDetailsPageTitle={config.promotionDetailsPageTitle}
      hideBreadcrumbs={config.hideBreadcrumbs}
    >
      <ThemeWrapper
        appName={config.appName}
        themeOverrides={config.themeOverrides}
      >
        {children}
      </ThemeWrapper>
    </AppContextProvider>
  )
}
