import React, { createContext, useReducer } from 'react'
import {
  PromotionListState,
  PromotionListAction,
  promotionListReducer,
} from '../reducers/promotionListReducer'

const defaultState = {
  promos: [],
  loadingPromotions: true,
  ongoingOptIn: [],
  ongoingPromotionCalls: [],
  queue: [],
  page: 1,
  totalPromotions: 0,
}

export const PromoListContext = createContext<{
  state: PromotionListState
  dispatch: React.Dispatch<PromotionListAction>
}>({
  state: defaultState,
  dispatch: () => undefined,
})

export const PromoListContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(promotionListReducer, defaultState)

  return (
    <PromoListContext.Provider value={{ state, dispatch }}>
      {children}
    </PromoListContext.Provider>
  )
}
