import React, { useContext, useEffect } from 'react'
import { AppStateContext } from '../../../contexts/AppContext'
import { PromoDetailsContext } from '../../../contexts/PromotionDetailsContext'
import { useSinglePromotionData } from '../../../hooks/useSinglePromotionData'
import { useOptInPromotion } from '../../../hooks/useOptInPromotion'
import { PromotionDetails } from './PromotionDetails'
import { ErrorScreen } from '../ErrorScreen/ErrorScreen'
import { Loader } from '../../atoms'
import { getIsFreeSpinsReward } from '../../../utils/getIsFreeSpinsReward'

export const PromotionDetailsState: React.FC = () => {
  const context = useContext(AppStateContext)
  const {
    state: { promotion, isLoadingOptIn, isLoadingPromotion, isReloadPromotion },
  } = useContext(PromoDetailsContext)
  const { getPromotion } = useSinglePromotionData(context.promoCode)

  const { optIn } = useOptInPromotion()

  useEffect(() => {
    if (isLoadingOptIn && context.promoCode) {
      optIn(context.promoCode, getIsFreeSpinsReward(promotion))
    }
  }, [isLoadingOptIn])

  useEffect(() => {
    if (isReloadPromotion && context.promoCode) {
      getPromotion(context.promoCode)
    }
  }, [isReloadPromotion])

  return (
    <React.Fragment>
      {promotion && <PromotionDetails promotion={promotion} />}
      {!promotion && !isLoadingPromotion && (
        <ErrorScreen
          title={context.emptyState.title}
          message={context.emptyState.message}
        />
      )}
      {isLoadingPromotion && <Loader />}
    </React.Fragment>
  )
}
