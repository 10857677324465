import React, { createContext, useReducer } from 'react'
import {
  PromotionDetailsAction,
  PromotionDetailsState,
  promotionDetailsReducer,
} from '../reducers/promotionDetailsReducer'

const defaultState = {
  isLoadingPromotion: true,
  isLoadingOptIn: false,
  isCtaDisabled: false,
  isReloadPromotion: false,
}

export const PromoDetailsContext = createContext<{
  state: PromotionDetailsState
  dispatch: React.Dispatch<PromotionDetailsAction>
}>({
  state: defaultState,
  dispatch: () => undefined,
})

export const PromoDetailsContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(promotionDetailsReducer, defaultState)

  return (
    <PromoDetailsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </PromoDetailsContext.Provider>
  )
}
