import { IBaseImage } from '../components/atoms/Image/Image'
import { IPromotionImage } from '../types/Promotion'

export const getImageDetails = (
  images: IPromotionImage[],
  defaultImageUrl: string
): IBaseImage => {
  const defaultImage = images && images.length ? images[0].url : defaultImageUrl
  const set: string =
    images && images.length
      ? images.reduce((acc, current) => {
          return acc + current.url + ' ' + current.width + 'w,'
        }, '' as string)
      : defaultImageUrl

  return { url: defaultImage, set: set }
}
